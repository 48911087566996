import URL from 'url';

export const parseUrl = (url, parseQueryString) => {
  const { pathname, search, query } = URL.parse(url, parseQueryString);

  return ({
    pathname,
    search: search || '',
    query,
  });
};
