/* istanbul ignore file */
import PropTypes from 'prop-types';

export const MAP_HYBRID = 'hybrid';
export const MAP_FULL = 'full';

export const MAP_PARAM_NAME = 'mapView';

export const MAP_VIEW_PROP_TYPES = PropTypes.oneOf([MAP_HYBRID, MAP_FULL]);

export type MapView = typeof MAP_HYBRID | typeof MAP_FULL;

export type MapViewPropType = typeof MAP_FULL | typeof MAP_HYBRID;

export const MAP_VIEW_OPTIONS: MapViewPropType[] = [MAP_FULL, MAP_HYBRID];
