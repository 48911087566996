const promiseTimeout = (milliseconds, promise) => {
  const timeoutPromise = new Promise((resolve, reject) => {
    const wait = setTimeout(() => {
      clearTimeout(wait);
      reject(new Error(`Timed out after ${milliseconds}ms`));
    }, milliseconds);
  });

  // Returns a race between our timeout and the passed promise
  return Promise.race([
    promise,
    timeoutPromise,
  ]);
};

export default function fetchDataWithTimeout(fetchDataFunction, fetchDataParams, timeout) {
  return promiseTimeout(timeout, new Promise(resolve => (
    resolve(fetchDataFunction(fetchDataParams))
  )));
}
