import { removeCookie } from '../../../client/cookie/cookieService';
import { getAccountLink, SIGN_IN_PATH } from '../../../shared/accountLinks';
import bffRequest from '../../../shared/requests/bffRequest';

const isUnauthorised = (err) => err && err.status === 401;

export function request(endpoint) {
  return bffRequest(endpoint).then(null, (err) => {
    if (isUnauthorised(err)) {
      removeCookie('lmdstok', { domain: '.realcommercial.com.au', path: '/' });
    }
    return Promise.reject(err);
  });
}

export function requestWithLogout(endpoint) {
  return request(endpoint).then(null, (err) => {
    if (isUnauthorised(err)) {
      const lockeSignInLink = getAccountLink({ path: SIGN_IN_PATH });
      window.open(lockeSignInLink, '_self');
    }
    return Promise.reject(err);
  });
}
