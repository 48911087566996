import noop from 'lodash/noop';
import { logWarn } from '../../shared/log';

const buildStorageMethod = (provider, method) => (...args) => provider[method](...args);

const getJSONValue = getItem => (name) => {
  try {
    const valueStr = getItem(name);

    if (valueStr) { return JSON.parse(valueStr); }
  } catch (err) {
    logWarn('failed to parse storage:', name, err); // eslint-disable-line no-console
  }

  return undefined;
};

export default (providerName) => {
  if (typeof window === 'undefined') return {};
  const provider = window[providerName];

  /*
    Safari, in Private Browsing Mode, looks like it supports sessionStorage but all calls to setItem
    throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
    to avoid the entire page breaking, without having to do a check at each usage of Storage.
  */
  if (provider) {
    try {
      const key = 'listing-ui-QuotaExceededError-check';
      // try setting some mock value
      provider.setItem(key, 1);
      provider.removeItem(key);
    } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem; // eslint-disable-line no-underscore-dangle
      Storage.prototype.setItem = noop;
      logWarn('sessionStorage disabled', e);
    }
  }
  const setItem = buildStorageMethod(provider, 'setItem');
  const getItem = buildStorageMethod(provider, 'getItem');
  const removeItem = buildStorageMethod(provider, 'removeItem');

  return {
    setItem,
    getItem,
    removeItem,
    getJSONValue: getJSONValue(getItem),
  };
};
