import capitalize from 'lodash/capitalize';

const MAP_AREA_TEXT = 'Map area';

const formatLocalityString = (locality) =>
  locality
    .split(/[\s-]+/)
    .map(capitalize)
    .join(' ');

export default function getFormattedLocationFromRefinements(refinements) {
  const firstLocality = refinements.localities ? refinements.localities[0] : {};
  const { locality = 'Australia', subdivision = '', postcode = '' } = firstLocality;
  const localityStr = refinements.boundingBox ? MAP_AREA_TEXT : formatLocalityString(locality);
  const subdivisionStr = subdivision ? `, ${subdivision.toUpperCase()}` : '';
  const postcodeStr = postcode ? ` ${postcode}` : '';

  return `${localityStr}${subdivisionStr}${postcodeStr}`;
}
