import { getDefaultViewData } from '@realestate.com.au/iglu-data';
import tealiumWrapper from '../../../shared/tracking/tealium/tealiumWrapper';
import { constructUserData } from '../../../shared/tracking/tealium/trackingUtils';
import {
  TEALIUM_SITE,
  PAGE_TYPE_ERROR_PAGE,
} from '../../../shared/tracking/tealium/constants';

const buildPageSubSection = (siteSection, errorMessage) => `${TEALIUM_SITE}:${siteSection}:${errorMessage}`;

const trackNotFoundPageEvent = (props) => {
  const { siteSection, message } = props;
  const defaultViewData = getDefaultViewData({
    page: {
      pageNameOverride: `${TEALIUM_SITE}:${siteSection}:${PAGE_TYPE_ERROR_PAGE}`,
      site: TEALIUM_SITE,
      pageType: PAGE_TYPE_ERROR_PAGE,
      siteSection,
      siteSubSection: siteSection && buildPageSubSection(siteSection, message),
    },
  });

  tealiumWrapper.trackView([...defaultViewData, constructUserData]);
};

export {
  trackNotFoundPageEvent,
};
