import * as listingBffRepository from '../../searchResult/model/listingBffRepository';
import query from '../../searchResult/model/query';
import { getCookie, setCookie, removeCookie } from '../../../client/cookie/cookieService';
import localStorageService from '../../../client/service/localStorage';
import { replaceQueryString } from '../../searchResult/model/url/queryStringService';
import { RELEVANCY_SORT } from '../../searchResult/model/query/sortOptions';
import { clientOnlyCall } from '../../clientOnly';
import { translateInvestListings } from '../../searchResult/model/translateData';

import {
  SAVE_LISTING,
  SAVE_LISTING_SUCCESS,
  DELETE_LISTING,
  DELETE_LISTING_TO_SAVE,
  SHOW_SAVE_PROPERTY_OVERLAY,
  SHOW_PROPERTY_ALERT_OVERLAY,
  CREATE_EMAIL_ALERT,
  OPEN_TOOL_TIP,
  CLOSE_TOOL_TIP,
  TOGGLE_CONTENT_MODAL,
  SET_UI_ELEMENT_DIMENSIONS,
  STORE_INVEST_LISTINGS,
  SORT_INVEST_LISTINGS,
  UPDATE_YIELD_REQUEST,
  TOGGLE_MODAL_REQUEST,
  SET_SAVED_LISTINGS,
  OPEN_ENQUIRY_FORM,
  CLOSE_ENQUIRY_FORM,
  UPDATE_SELECTED_LISTING,
  UPDATE_SELECTED_CONTACT,
  UPDATE_SELECTED_DESIRED_INFORMATION,
  SHOW_PROGRESS_LOADER,
  HIDE_PROGRESS_LOADER,
  RESET_ACTIVE_SORT,
} from './constants';

export const saveListing = (listingId, successCallback) => async (dispatch, getState) => {
  // FIXME: keep only listing-id and bookmarkId and fix the frickin hyphen
  const {
    myRCA: { isSignedIn },
  } = getState();

  if (isSignedIn) {
    dispatch({ type: SAVE_LISTING, id: listingId });

    const myRcaToken = getCookie('lmdstok');
    const response = await listingBffRepository.saveListing(listingId, myRcaToken);

    if (successCallback) successCallback();

    return dispatch({
      type: SAVE_LISTING_SUCCESS,
      bookmarkId: response.id,
      listingId: response['listing-id'],
    });
  }

  setCookie('listingIdToSave', listingId, { expires: 1 });
  return dispatch({ type: SHOW_SAVE_PROPERTY_OVERLAY });
};

export function deleteListing(listingId, successCallback) {
  return (dispatch, getState) => {
    const {
      myRCA: { savedListings },
    } = getState();
    const savedListingIndex = savedListings.map((s) => s['listing-id']).indexOf(listingId);
    const bookmarkId = savedListings[savedListingIndex].id;

    const myRcaToken = getCookie('lmdstok');
    dispatch({ type: DELETE_LISTING, id: listingId });
    listingBffRepository.deleteListing(bookmarkId, myRcaToken);
    if (successCallback) successCallback();
  };
}

export function createEmailAlert() {
  return (dispatch, getState) => {
    const {
      myRCA: { isSignedIn },
    } = getState();
    if (!isSignedIn) {
      return dispatch({ type: SHOW_PROPERTY_ALERT_OVERLAY });
    }
    const myRcaToken = getCookie('lmdstok');
    dispatch({ type: CREATE_EMAIL_ALERT });
    listingBffRepository.createEmailAlert(query.getQueryFromUrl(), myRcaToken);
  };
}

export const openToolTip = (content, targetElement, options) => ({
  type: OPEN_TOOL_TIP,
  content,
  targetElement,
  options,
});

export const closeToolTip = () => ({
  type: CLOSE_TOOL_TIP,
});

export const toggleContentModal = (modalElement) => ({
  type: TOGGLE_CONTENT_MODAL,
  modalElement,
});

export const setUIElementDimensions = (id, width, height) => ({
  type: SET_UI_ELEMENT_DIMENSIONS,
  width,
  height,
  id,
});

export const dismissToolTipAndSetLocalStorage = (localStorageKey, value) => {
  localStorageService().setItem(localStorageKey, value);
  return closeToolTip();
};

export const toggleModalRequest = (pageSource) => ({
  type: TOGGLE_MODAL_REQUEST,
  pageSource,
});

const defaultSortOption = RELEVANCY_SORT;

export const storeInvestListings = (listings) => ({
  type: STORE_INVEST_LISTINGS,
  listings: translateInvestListings(listings),
});

const clientReplaceQueryString = clientOnlyCall(replaceQueryString);

export const updateYield = (newYield, yieldOnlyForUnpricedListings = true) => {
  clientReplaceQueryString({ yieldValue: newYield, yieldOnlyForUnpricedListings });

  return {
    type: UPDATE_YIELD_REQUEST,
    newYield: newYield && Number(newYield),
    yieldOnlyForUnpricedListings,
  };
};

export const sortInvestListings = (sortOption) => {
  const { name, direction } = sortOption;
  clientReplaceQueryString({ activeSort: `${name}-${direction}` });

  return {
    type: SORT_INVEST_LISTINGS,
    sortOption,
  };
};

export const prepareAndSortInvestListings =
  (
    listings,
    newYield,
    yieldOnlyForUnpricedListings,
    sortOption = defaultSortOption,
    storeInvestListingsAction = storeInvestListings,
    updateYieldAction = updateYield,
    sortInvestListingsAction = sortInvestListings,
  ) =>
    (dispatch) => {
      dispatch(storeInvestListingsAction(listings));
      dispatch(updateYieldAction(newYield, yieldOnlyForUnpricedListings));
      dispatch(sortInvestListingsAction(sortOption));
    };

export const updateYieldAndSortListings =
  (
    newYield,
    yieldOnlyForUnpricedListings,
    sortOption = defaultSortOption,
    updateYieldAction = updateYield,
    sortInvestListingsAction = sortInvestListings,
  ) =>
    (dispatch) => {
      dispatch(updateYieldAction(newYield, yieldOnlyForUnpricedListings));
      dispatch(sortInvestListingsAction(sortOption));
    };

export const getSavedListings = (trackSavePropertyEvent) => async (dispatch, getState) => {
  const {
    myRCA: { token, listingIdToSave },
  } = getState();

  if (token) {
    if (listingIdToSave) {
      await dispatch(saveListing(listingIdToSave, trackSavePropertyEvent));
      dispatch({
        type: DELETE_LISTING_TO_SAVE,
      });
      removeCookie('listingIdToSave');
    }

    return listingBffRepository
      .getSavedListings(token)
      .then((response) =>
        dispatch({
          type: SET_SAVED_LISTINGS,
          savedListings: response.results,
        }),
      )
      .catch(() =>
        dispatch({
          type: SET_SAVED_LISTINGS,
          savedListings: [],
        }),
      );
  }

  return dispatch({
    type: SET_SAVED_LISTINGS,
    savedListings: [],
  });
};

export const openEnquiry = () => ({
  type: OPEN_ENQUIRY_FORM,
});

export const closeEnquiry = () => ({
  type: CLOSE_ENQUIRY_FORM,
});

export const updateSelectedListing = (listing) => ({
  type: UPDATE_SELECTED_LISTING,
  listing,
});

export const updateSelectedContact = (contact) => ({
  type: UPDATE_SELECTED_CONTACT,
  contact,
});

export const updateSelectedDesiredInformation = (desiredInformation) => ({
  type: UPDATE_SELECTED_DESIRED_INFORMATION,
  desiredInformation,
});

export const showProgressLoader = () => ({
  type: SHOW_PROGRESS_LOADER,
  isLoading: true,
});

export const hideProgressLoader = () => ({
  type: HIDE_PROGRESS_LOADER,
  isLoading: false,
});

export const resetActiveSort = () => ({
  type: RESET_ACTIVE_SORT,
});
