/* eslint-disable max-len */
export const TEALIUM_ENV = 'TEALIUM_ENV';
export const TEALIUM_SITE = 'rca';
export const PROFILE_RCA_MAIN = 'rca-main';
export const PROFILE_SP_ONLY = 'sp-only';

// Schemas

export const TEALIUM_SCHEMA_USER = 'iglu:au.com.realestate/user/jsonschema/2-0-5';
export const TEALIUM_SCHEMA_LISTING = 'iglu:au.com.realestate/listing/jsonschema/6-0-4';
export const TEALIUM_SCHEMA_PROPERTY = 'iglu:au.com.realestate/property/jsonschema/3-1-3';
export const TEALIUM_SCHEMA_SHARE_LISTING = 'iglu:au.com.realestate/share_listing/jsonschema/1-0-4';
export const TEALIUM_SCHEMA_VIEW_EMAIL_AGENT_FORM =
  'iglu:au.com.realestate/view_email_agent_form/jsonschema/3-0-2';
export const TEALIUM_SCHEMA_AGENTS = 'iglu:au.com.realestate/agents/jsonschema/1-0-2';
export const TEALIUM_SCHEMA_AGENT_MOBILE_NUMBER_REVEAL =
  'iglu:au.com.realestate/agent_mobile_number_reveal/jsonschema/3-0-1';
export const TEALIUM_SCHEMA_GET_DIRECTIONS =
  'iglu:au.com.realestate/get_directions/jsonschema/1-0-2';
export const TEALIUM_SCHEMA_EXPAND_MAP = 'iglu:au.com.realestate/expand_map/jsonschema/1-0-1';
export const TEALIUM_SCHEMA_STREET_VIEW_CLICK =
  'iglu:au.com.realestate/street_view_click/jsonschema/1-0-1';
export const TEALIUM_SCHEMA_AGENT_MOBILE_NUMBER_REVEAL_MODAL_OPEN =
  'iglu:au.com.realestate/agent_mobile_number_reveal_modal_open/jsonschema/1-0-1';
export const TEALIUM_SCHEMA_CLICK_EXTERNAL_LINK =
  'iglu:au.com.realestate/click_external_link/jsonschema/1-0-3';
export const TEALIUM_SCHEMA_IN_PAGE_NAVIGATION =
  'iglu:au.com.realestate/in_page_navigation/jsonschema/1-0-2';
export const TEALIUM_SCHEMA_EMAIL_LEAD_TO_AGENT =
  'iglu:au.com.realestate/email_lead_to_agent/jsonschema/3-0-5';
export const TEALIUM_SCHEMA_VIEW_AGENT_SOCIAL_MEDIA =
  'iglu:au.com.realestate/view_agent_social_media/jsonschema/1-0-2';
export const TEALIUM_SCHEMA_FULL_SCREEN_CAROUSEL_VIEW =
  'iglu:au.com.realestate/full_screen_carousel_view/jsonschema/2-0-2';
export const TEALIUM_SCHEMA_VIDEO_PLAYED = 'iglu:au.com.realestate/video_played/jsonschema/2-1-5';
export const TEALIUM_SCHEMA_SAVE_PROPERTY = 'iglu:au.com.realestate/save_property/jsonschema/1-0-2';
export const TEALIUM_SCHEMA_MEDIA_ICON_CLICK =
  'iglu:au.com.realestate/media_icon_click/jsonschema/1-0-4';
export const TEALIUM_SCHEMA_3D_TOUR_PLAYED =
  'iglu:au.com.realestate/3d_tour_played/jsonschema/1-0-1';
export const TEALIUM_SCHEMA_PRINT_PAGE = 'iglu:au.com.realestate/print_page/jsonschema/1-0-1';
export const TEALIUM_SCHEMA_AGENCY_WEBSITE_CLICK =
  'iglu:au.com.realestate/agency_website_click/jsonschema/1-0-2';
export const TEALIUM_SCHEMA_CALENDAR_SAVE = 'iglu:au.com.realestate/calendar_save/jsonschema/1-0-1';
export const TEALIUM_SCHEMA_LISTING_SEARCH_RESULTS =
  'iglu:au.com.realestate/listing_search_results/jsonschema/6-0-6';
export const TEALIUM_SCHEMA_LISTING_SEARCH_PARAMETERS =
  'iglu:au.com.realestate/listing_search_parameters/jsonschema/5-0-15';
export const TEALIUM_SCHEMA_NEW_NEARBY_PROPERTY_IMPRESSION =
  'iglu:au.com.realestate/nearby_property_impression/jsonschema/1-0-1';
export const TEALIUM_SCHEMA_FIRST_SEARCH_RESULT =
  'iglu:au.com.realestate/first_search_result/jsonschema/6-0-2';
export const TEALIUM_SCHEMA_TRACK_IMAGE_CAROUSEL =
  'iglu:au.com.realestate/search_result_carousel_view/jsonschema/2-0-2';
export const TEALIUM_SCHEMA_GET_ALERTS = 'iglu:au.com.realestate/get_alert/jsonschema/1-0-0';
export const TEALIUM_SCHEMA_MODAL_OPEN =
  'iglu:au.com.realestate/my_rea_modal_open/jsonschema/2-0-9';
export const TEALIUM_SCHEMA_NEARBY_LOCATION_SELECTED =
  'iglu:au.com.realestate/nearby_location_selected/jsonschema/1-0-0';
export const TEALIUM_SCHEMA_TRACK_SET_MY_YIELD =
  'iglu:au.com.realestate/set_yield_value/jsonschema/1-0-0';
export const TEALIUM_SCHEMA_EXPERIMENTS = 'iglu:au.com.realestate/experiments/jsonschema/1-0-0';
export const TEALIUM_SEARCH_REFINEMENT_MODAL_OPEN =
  'iglu:au.com.realestate/search_refinement_modal_open/jsonschema/1-0-0';
export const TEALIUM_SCHEMA_TOOLTIP_DISPLAYED =
  'iglu:au.com.realestate/tooltip_displayed/jsonschema/1-0-7';
export const TEALIUM_SCHEMA_FLOATING_PILL_INTERACTION =
  'iglu:au.com.realestate/floating_pill_interaction/jsonschema/2-0-0';
export const TEALIUM_SCHEMA_CAROUSEL_INTERACTION =
  'iglu:au.com.realestate/carousel_interaction/jsonschema/1-0-0';
export const TEALIUM_SCHEMA_CAROUSEL_IMPRESSION =
  'iglu:au.com.realestate/carousel_impression/jsonschema/1-0-1';

// Page types

export const PAGE_TYPE_DETAIL = 'property details';
export const PAGE_TYPE_SEARCH_RESULT = 'search result - list';
export const PAGE_TYPE_HYBRID_SEARCH_RESULT = 'srp-map-hybrid';
export const PAGE_TYPE_SEARCH_RESULT_MAP = 'search result - map';
export const PAGE_TYPE_HOME_PAGE = 'homepage';
export const PAGE_TYPE_ERROR_PAGE = 'error page';

export const FIND_AGENT_SITE_SECTION = 'find agent';
export const CUSTOMER_AGENCY = 'agency';

// Page sections

export const STICKY_HEADER = 'sticky header';
export const STICKY_FOOTER = 'sticky footer';
export const CONTACT_SIDE_PANEL = 'contact side panel';
export const AGENCY_PANEL = 'agency panel';
export const MEDIA_VIEWER_VIDEO_PLAYER = 'media_viewer_video_player';
export const VIDEO_PLATFORM_YOUTUBE = 'youtube';
export const MEDIA_VIEWER_ICON = 'media_viewer_icon';
export const DIGITAL_INSPECTIONS = 'digital_inspections';
export const ADDITIONAL_INFORMATION = 'additional information';
export const CALENDAR_TYPE_AUCTION = 'auction';
export const VIDEO_PLATFORM_OTHER = 'other';
export const USER_ACTION_SAVE = 'save';
export const USER_ACTION_UNSAVE = 'unsave';
export const USER_ACTION_SUBSCRIBE = 'subscribe';
export const USER_ACTION_EDIT = 'edit';
export const USER_ACTION_SETVALUE = 'set_value';
export const FREQUENCY_DAILY = 'daily';
export const MEDIA_VIEWER_ENQUIRY_SLIDE = 'media_viewer_enquiry_slide';
export const LISTING_DESCRIPTION_BODY = 'listing-description-body';
export const SRP_EMAIL_BUTTON = 'srp_email_button';

// Sources

export const GLOBAL_TRACKING_KEY = 'trackingThroughSourceKey';
export const SOURCE_ELEMENT_KEY = 'element';
export const SOURCE_PAGE_KEY = 'page';
export const SOURCE_TYPE_KEY = 'type';
export const SRP_AGENCY_WIDGET_SOURCE_ELEMENT = 'srp_agency_search_widget';
export const SRP_AGENCY_PILL_SOURCE_ELEMENT = 'srp_agency_search_pill';
export const SRP_MARKET_INSIGHTS_WIDGET_SOURCE_ELEMENT = 'srp_market_insights_widget';

// Campaign Tracking
export const CAMPAIGN_TYPE = 'internal';
export const CAMPAIGN_CHANNEL = 'in_product';
export const CAMPAIGN_SOURCE_RCA = 'rca';
export const CAMPAIGN_NAME_EXTRA_LOCATION = 'extra_location';

// Market Insights Tracking
export const MARKET_INSIGHTS_PRODUCT = 'Market Insights';
export const MARKET_INSIGHTS_MEDIAN_PRICE_TOOLTIP_DESCRIPTOR = 'Median advised sale price';
export const MARKET_INSIGHTS_MEDIAN_TIME_TOOLTIP_DESCRIPTOR = 'Median time on market';
export const MARKET_INSIGHTS_SOLD_TOOLTIP_DESCRIPTOR = 'Properties sold';
export const MARKET_INSIGHTS_AVAILABLE_TOOLTIP_DESCRIPTOR = 'Properties available for sale';
