/* eslint-disable no-new */
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { googleMapLoader } from '../../shared/loadMapScript';

export const buildMarker = ({ googleMaps = window?.google?.maps, mapRef, lat, lng }) => {
  const MARKER_URL = '/listing-ui-assets/img/map-marker.svg';

  new googleMaps.Marker({
    position: {
      lat,
      lng,
    },
    map: mapRef,
    animation: googleMaps.Animation.DROP,
    icon: {
      scaledSize: new googleMaps.Size(22, 31),
      origin: new googleMaps.Point(0, 0),
      anchor: new googleMaps.Point(11, 31),
      url: MARKER_URL,
    },
  });
};

const Map = ({ options, onMount, className }) => {
  const divProps = { ref: useRef(), className };

  useEffect(() => {
    googleMapLoader()
      .importLibrary('maps')
      .then(({ Map: GoogleMap }) => {
        const map = new GoogleMap(divProps.ref.current, options);
        onMount?.(map); // eslint-disable-line no-unused-expressions
      });
  }, [divProps.ref, onMount, options]);

  return <div {...divProps} />;
};

Map.propTypes = {
  options: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    zoom: PropTypes.number.isRequired,
  }).isRequired,
  onMount: PropTypes.func,
  className: PropTypes.string,
};

export default Map;
