export type NonNullablePartial<T> = Pick<
  T,
  {
    [K in keyof T]-?: undefined extends T[K] ? never : K;
  }[keyof T]
>;

export const stripUndefinedProperties = <T extends Record<string, any>>(
  obj: T,
): NonNullablePartial<T> => {
  const keys = Object.keys(obj) as Array<keyof NonNullablePartial<T>>;
  const definedKeys = keys.filter((key) => obj[key] !== undefined);
  const definedObj = {} as NonNullablePartial<T>;

  definedKeys.forEach((key) => {
    definedObj[key] = obj[key];
  });

  return definedObj;
};
