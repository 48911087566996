import { breakpoints } from '../../styles/_variables';

export const MEDIUM_SCREEN_SIZE = breakpoints.breakpointSmMd;
export const LARGE_SCREEN_SIZE = breakpoints.breakpointLg;

export const supportsTouchScreen: () => boolean = () => !!('ontouchstart' in window);

export const getMediumScreenMatchMedia = (): MediaQueryList =>
  window.matchMedia(`(min-width: ${MEDIUM_SCREEN_SIZE})`);

export const getLargeScreenMatchMedia = (): MediaQueryList =>
  window.matchMedia(`(min-width: ${LARGE_SCREEN_SIZE})`);

export const isMediumScreen: () => boolean = () => {
  const { matches } = window.matchMedia(`(min-width: ${MEDIUM_SCREEN_SIZE})`);
  return matches;
};

export const isLargeScreen: () => boolean = () => {
  const { matches } = window.matchMedia(`(min-width: ${LARGE_SCREEN_SIZE})`);
  return matches;
};
