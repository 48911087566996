import merge from 'lodash/merge';
import type { Location } from '../../../../shared/model/tsTypes';

function extractLocationParts(location: string, pattern: string) {
  const hasLocationMatch = location.match(pattern);

  if (!hasLocationMatch) return {};

  const [, locality, subdivision, postcode] = hasLocationMatch;

  return {
    locality,
    subdivision,
    postcode,
  };
}

function translateSearchLocation(location: string) {
  const START = '^';
  const END = '$';
  const suburbGroup = '(?:(.*?))';
  const stateGroup = '(?:\\s(wa|nt|sa|qld|nsw|act|vic|tas|nz))';
  const postcodeGroup = '(?:\\s(\\d{4}))';
  const allRegex = `${START}${suburbGroup}?${stateGroup}?${postcodeGroup}?${END}`;
  const { locality, subdivision, postcode } = extractLocationParts(location, allRegex);
  const subdivisionObj = subdivision ? { subdivision } : {};
  const postcodeObj = postcode ? { postcode } : {};

  return merge({ locality: locality?.trim() }, subdivisionObj, postcodeObj);
}

function getUrlLocations(location?: string): string[] | undefined {
  if (!location) return undefined;

  return decodeURIComponent(location)
    .replace(/[\s-]+/g, ' ')
    .split(',')
    .filter(Boolean);
}

export function parseLocationUrl(locations?: string): Location[] | undefined {
  return locations ? getUrlLocations(locations)?.map(translateSearchLocation) : undefined;
}
