import some from 'lodash/some';
import take from 'lodash/take';
import { setCookie, getCookie } from '../cookie/cookieService';
import refinementsFromUrl from '../../routes/searchResult/model/refinementsFromUrl';
import getFormattedLocationFromRefinements from '../../routes/searchResult/model/getFormattedLocationFromRefinements';

export const RECENT_SEARCHES_KEY = 'recentSearches';

function updateRecentSearches(recentSearches = []) {
  setCookie(RECENT_SEARCHES_KEY, JSON.stringify(recentSearches), { expires: 30 });
}

export function getRecentSearches() {
  const existingSearches = getCookie(RECENT_SEARCHES_KEY);

  return existingSearches ? JSON.parse(existingSearches) : [];
}

export function saveRecentSearch({ pathname, search }) {
  const url = [pathname, search].filter(Boolean).join('?');
  const refinements = refinementsFromUrl(url);
  const existingSearches = getRecentSearches();
  const { channelObject, propertyTypes, localities } = refinements;
  const extraLocalitiesCount = (localities && localities.length - 1) || 0;
  const extraLocalitiesString = extraLocalitiesCount ? ` and ${extraLocalitiesCount} more` : '';
  const name = `${getFormattedLocationFromRefinements(refinements)}${extraLocalitiesString}`;
  const propertyTypesText = propertyTypes
    ? propertyTypes.map((propertyType) => propertyType.longDisplayText).join(', ')
    : 'All Property Types';
  const detailText = [channelObject?.humanReadable, propertyTypesText].filter(Boolean).join(' - ');
  const newSearch = {
    url,
    name,
    detailText,
  };
  const searchExists = some(existingSearches, { url: newSearch.url });
  const latest3Searches = take(existingSearches, 3);
  const updatedSearches = searchExists ? existingSearches : [newSearch, ...latest3Searches];

  updateRecentSearches(updatedSearches);
}

export function deleteRecentSearch(url) {
  const existingSearches = getRecentSearches();
  const indexToDelete = existingSearches.findIndex((search) => search.url === url);
  existingSearches.splice(indexToDelete, 1);

  updateRecentSearches(existingSearches);
}
