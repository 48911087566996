import {
  filtersFromRefinements,
  sortOrderFactory,
  currentLocationFactory,
} from './query/filtersFromRefinements';
import type { BoundingBoxType, RefinementsType } from '../../../shared/model/constants/filterTypes';
import type { NonNullablePartial } from '../../../shared/stripUndefinedProperties';
import { stripUndefinedProperties } from '../../../shared/stripUndefinedProperties';
import type { Location, SortOptionType } from '../../../shared/model/tsTypes';

type BoundingBoxSearchType = {
  northEast: {
    latitude: string;
    longitude: string;
  };
  southWest: {
    latitude: string;
    longitude: string;
  };
};

export type QueryFromRefinementsType = {
  channel: string;
  localities?: Location[];
  filters: ReturnType<typeof filtersFromRefinements>;
  page?: number;
  sort?: { order: Pick<SortOptionType, 'bff'> };
  currentLocation?: string;
  where?: string;
  'page-size'?: number;
  'tenure-type'?: string;
  boundingBoxSearch?: BoundingBoxSearchType;
};

const boundingBoxToQuery = (boundingBox?: BoundingBoxType): BoundingBoxSearchType | undefined => {
  if (!boundingBox) return undefined;

  return {
    northEast: {
      latitude: boundingBox.northEastLatitude.toString(),
      longitude: boundingBox.northEastLongitude.toString(),
    },
    southWest: {
      latitude: boundingBox.southWestLatitude.toString(),
      longitude: boundingBox.southWestLongitude.toString(),
    },
  };
};

export default (refinements: RefinementsType): NonNullablePartial<QueryFromRefinementsType> => {
  const currentLocation = currentLocationFactory(refinements.currentLocation, refinements.where);
  const maybeTenureType = refinements.tenureTypeObject?.bff;
  const filters = {
    ...filtersFromRefinements(refinements),
    ...(maybeTenureType ? { 'tenure-type': maybeTenureType } : {}),
  };
  const cleanQuery = stripUndefinedProperties({
    channel: refinements.channelObject.id,
    localities: refinements.localities,
    filters,
    page: refinements.page,
    sort: sortOrderFactory(refinements.channelObject, refinements.sortObject),
    ...currentLocation,
    'page-size': refinements.pageSize,
    boundingBoxSearch: boundingBoxToQuery(refinements.boundingBox),
  });

  return cleanQuery;
};
