import React, { useState, useEffect, useContext, createContext } from 'react';
import { getLargeScreenMatchMedia, getMediumScreenMatchMedia } from './browser';

type Media = {
  isMediaReady: boolean;
  isMediumScreen?: boolean;
  isLargeScreen?: boolean;
};
type MediaProviderProps = {
  children?: React.ReactNode;
};
const MediaContext = createContext<Media>({ isMediaReady: false });

export const MediaProvider = ({ children }: MediaProviderProps): React.ReactElement => {
  const [isMediaReady, setIsMediaReady] = useState<boolean>(false);
  const [isMediumScreen, setIsMediumScreen] = useState<boolean>();
  const [isLargeScreen, setIsLargeScreen] = useState<boolean>();

  useEffect(() => {
    const isMediumScreenListener = (e: { matches: boolean }) => {
      setIsMediumScreen(e.matches);
      setIsMediaReady(true);
    };

    const mediumScreenMedia = getMediumScreenMatchMedia();
    mediumScreenMedia.addEventListener('change', isMediumScreenListener);

    isMediumScreenListener(mediumScreenMedia);

    const isLargeScreenListener = (e: { matches: boolean }) => {
      setIsLargeScreen(e.matches);
      setIsMediaReady(true);
    };

    const largeScreenMedia = getLargeScreenMatchMedia();
    largeScreenMedia.addEventListener('change', isLargeScreenListener);

    isLargeScreenListener(largeScreenMedia);

    return () => {
      mediumScreenMedia.removeEventListener('change', isMediumScreenListener);
      largeScreenMedia.removeEventListener('change', isLargeScreenListener);
    };
  }, []);

  return (
    <MediaContext.Provider value={{ isMediaReady, isMediumScreen, isLargeScreen }}>
      {children}
    </MediaContext.Provider>
  );
};

const useMedia = (): Media => useContext(MediaContext);

export default useMedia;
