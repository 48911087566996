import {
  OPEN_ENQUIRY_FORM,
  CLOSE_ENQUIRY_FORM,
  UPDATE_SELECTED_LISTING,
  UPDATE_SELECTED_CONTACT,
  UPDATE_SELECTED_DESIRED_INFORMATION,
} from '../actions/constants';

export const initialState = {
  enquiryOpen: false,
};

export default (state = initialState, action) => {
  const actions = {
    [OPEN_ENQUIRY_FORM]: { enquiryOpen: true },
    [CLOSE_ENQUIRY_FORM]: { enquiryOpen: false, selectedContact: undefined },
    [UPDATE_SELECTED_LISTING]: { selectedListing: action.listing },
    [UPDATE_SELECTED_CONTACT]: { selectedContact: action.contact },
    [UPDATE_SELECTED_DESIRED_INFORMATION]: { selectedDesiredInformation: action.desiredInformation },
  };

  return {
    ...state,
    ...actions[action.type],
  };
};
