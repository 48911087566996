import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import includes from 'lodash/includes';
import { channelObjects } from '../../../../shared/model/constants/channels';
import { DATE_DESC_SORT } from './sortOptions';
import type { AllChannelObjects, SortOptionType } from '../../../../shared/model/tsTypes';
import type { RefinementsType } from '../../../../shared/model/constants/filterTypes';

function generateSearchWithin(searchWithin?: string) {
  if (!searchWithin) return { 'surrounding-suburbs': true };
  if (searchWithin === 'excludesurrounding') {
    return {
      'within-radius': searchWithin,
    };
  }

  return {
    'within-radius': searchWithin,
    'surrounding-suburbs': true,
  };
}

const sortIsDateDescending = (sortOption: SortOptionType) =>
  sortOption.value === DATE_DESC_SORT.value;

function minMaxObject(minValue?: number, maxValue?: number) {
  if (!minValue && !maxValue) {
    return undefined;
  }

  return {
    minimum: minValue?.toString(),
    maximum: maxValue?.toString(),
  };
}

function sanitizeNumber(price: number | string) {
  const priceNumber = Number(price);

  if (Number.isNaN(priceNumber)) return undefined;

  return price;
}

function getCurrentLocations(currentLocation: string) {
  return decodeURIComponent(currentLocation).split(',');
}

export function filtersFromRefinements(refinements: RefinementsType) {
  const filters = {
    'property-types': refinements.propertyTypes?.map((type) => type.id) || undefined,
    keywords: refinements.keywords,
    'price-range': minMaxObject(refinements.minPrice, refinements.maxPrice),
    'floor-area': minMaxObject(refinements.minFloorArea, refinements.maxFloorArea),
    'land-size': minMaxObject(refinements.minSiteArea, refinements.maxSiteArea),
    'minimum-parking-spaces': refinements.numParkingSpaces,
    'energy-efficiency-rating': refinements.energyEfficiency,
  };

  return pickBy(merge(filters, generateSearchWithin(refinements.includePropertiesWithin)));
}

export type CurrentLocationType = {
  'reference-coordinate': {
    longitude: string;
    latitude: string;
  };
};

export function currentLocationFactory(
  shouldUseCurrentLocation?: boolean | string[],
  currentLocation?: string,
): CurrentLocationType | undefined {
  if (!shouldUseCurrentLocation || !currentLocation) {
    return undefined;
  }

  const location = getCurrentLocations(currentLocation);
  const geolocationLength = 2;
  const longitude = location[0];
  const latitude = location[1];

  if (
    shouldUseCurrentLocation &&
    location.length === geolocationLength &&
    sanitizeNumber(longitude) &&
    sanitizeNumber(latitude)
  ) {
    return {
      'reference-coordinate': {
        longitude,
        latitude,
      },
    };
  }

  return undefined;
}

export function sortOrderFactory(channelObject: AllChannelObjects, sortObject?: SortOptionType) {
  if (!sortObject || !channelObject) return undefined;
  if (
    includes([channelObjects.Sold.id, channelObjects.Leased.id], channelObject.id) &&
    sortIsDateDescending(sortObject)
  ) {
    return { order: 'sold-leased-date-newest-first' };
  }

  return sortObject.bff ? { order: sortObject.bff } : undefined;
}
