import get from 'lodash/get';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import {
  STORE_INVEST_LISTINGS,
  SORT_INVEST_LISTINGS,
  UPDATE_YIELD_REQUEST,
  TOGGLE_MODAL_REQUEST,
  RESET_ACTIVE_SORT,
} from '../actions/constants';
import { calculatePrice, calculateYield } from '../../searchResult/Investor/utils/calculations';
import { RELEVANCY } from '../../searchResult/model/query/sortOptions';

export const initialInvestState = {
  listings: [],
  yieldValue: null,
  isModalOpen: false,
  yieldOnlyForUnpricedListings: true,
};

const getSortProperty = sortOption => (item) => {
  if (get(item, sortOption.name) != null) { // Undefined or null
    return get(item, sortOption.name);
  }

  // Property is undefined or null so we need to force the value to the end depending on direction
  return sortOption.direction === 'desc' ? -Infinity : Infinity;
};

const calculatePriceAndYield = (listings, newYield, yieldOnlyForUnpricedListings) => map(listings, listing => ({
  ...listing,
  price: calculatePrice(
    listing.tenancy.netIncome,
    newYield,
    listing.listedPrice,
    yieldOnlyForUnpricedListings,
  ),
  calculatedYield: calculateYield(
    listing.tenancy.netIncome,
    newYield,
    listing.listedPrice,
    yieldOnlyForUnpricedListings,
  ),
}));

const sortbByOption = (listings, sortOption) => orderBy(
  listings,
  [getSortProperty(sortOption), RELEVANCY],
  [sortOption.direction, 'asc'],
);

export default (state = initialInvestState, action) => {
  switch (action.type) {
    case STORE_INVEST_LISTINGS:
      return {
        ...state,
        listings: action.listings,
      };

    case UPDATE_YIELD_REQUEST:
      return {
        ...state,
        yieldValue: action.newYield,
        yieldOnlyForUnpricedListings: action.yieldOnlyForUnpricedListings,
        listings: calculatePriceAndYield(state.listings, action.newYield, action.yieldOnlyForUnpricedListings),
      };

    case SORT_INVEST_LISTINGS:
      return {
        ...state,
        activeSort: action.sortOption,
        listings: sortbByOption(state.listings, action.sortOption),
      };

    case TOGGLE_MODAL_REQUEST:
      return {
        ...state,
        pageSource: action.pageSource,
        isModalOpen: !state.isModalOpen,
      };

    case RESET_ACTIVE_SORT:
      return {
        ...state,
        activeSort: undefined,
      };

    default:
      return state;
  }
};
