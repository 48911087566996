import map from 'lodash/map';
import find from 'lodash/find';

const minParkingSpaces = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 25, 50, 100, 250, 500, 1000];
const numberOptions = map(minParkingSpaces, (n) => ({
  value: n,
  displayText: `${n}+`,
}));

export function validateParkingSpaces(numParkingSpaces) {
  const validParkingSpace = find(numberOptions, (opt) => opt.value == numParkingSpaces);
  return validParkingSpace ? validParkingSpace.value : undefined;
}
