import { parseConfig } from './config';

export const SIGN_IN_PATH = '/sign-in';
export const SIGN_UP_PATH = '/sign-up';
interface Params {
  path: string;
  continueUrl?: string;
  intent?: string;
}

interface Config {
  WEBSITE_ROOT: string;
}

// eslint-disable-next-line arrow-body-style
export const getAccountLink = ({ path, continueUrl = '/', intent }: Params) => {
  const { WEBSITE_ROOT } = parseConfig() as Config;
  const encodedContinueUrl = encodeURIComponent(continueUrl);
  const encodedIntent = intent ? encodeURIComponent(intent) : null;

  const url = `${WEBSITE_ROOT}${path}?continueUrl=${encodedContinueUrl}`;
  if (!encodedIntent) {
    return url;
  }

  return `${url}&intent=${encodedIntent}`;
};
