import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { parseConfig, getIsomorphicUrl } from '../config';
import httpService from '../../routes/httpService';
import reportError from '../../client/tracking/errorReporter';

export const bffUrl = ({ path, query = {} }) => {
  const {
    LISTING_BFF_ORIGIN_ENDPOINT,
    LISTING_BFF_ENDPOINT,
  } = parseConfig();
  const ISOMORPHIC_BFF_URL = getIsomorphicUrl(LISTING_BFF_ORIGIN_ENDPOINT, LISTING_BFF_ENDPOINT);
  const queryStringParts = map(query, (value, key) => (value?.length ? `${key}=${value}` : '')).filter(Boolean).join('&');
  const queryString = queryStringParts ? `?${queryStringParts}` : '';

  return `${ISOMORPHIC_BFF_URL}/listing-ui/${path}${queryString}`;
};

const buildBffRequestObject = ({ method, header, body, path, query = {} }) => {
  const bffPath = bffUrl({ path, query });
  const requestObject = {
    path: bffPath,
    method,
    body,
    header,
  };
  // the token will expired after 5 mins
  // we need tp pass the HTTP-only cookie('refreshToken') to BFF for automatically refresh token
  // credentials: 'include' used to pass cookie when there has CORS
  if (header && !isEmpty(header.token)) {
    requestObject.credentials = 'include';
  }
  const cleanRequestObject = JSON.parse(JSON.stringify(requestObject));

  return cleanRequestObject;
};

export default (request) => {
  const requestObject = buildBffRequestObject(request);

  return httpService.request(requestObject).catch((error) => {
    reportError(`An error occurred requesting ${requestObject.path}`, error);
    throw error;
  });
};
