import PropTypes from 'prop-types';

export const SESSION_VARIANT_PROP_TYPES = PropTypes.shape({
  [PropTypes.string]: PropTypes.string,
});

export const EXPERIMENTS_CONFIG_PROP_TYPES = PropTypes.shape({
  experimentName: PropTypes.string.isRequired,
  variants: PropTypes.arrayOf(PropTypes.string).isRequired,
});

export const EXPERIMENTS_REGISTRY_PROP_TYPE = PropTypes.shape({
  experiments: PropTypes.arrayOf(EXPERIMENTS_CONFIG_PROP_TYPES),
  sessionVariants: SESSION_VARIANT_PROP_TYPES,
  createExperimentSessionsAndStore: PropTypes.func,
  getAllSessions: PropTypes.func,
  isActiveVariant: PropTypes.func,
  experimentForTracking: PropTypes.func,
  getAllExperimentsForTracking: PropTypes.func,
});
