import { createBrowserHistory } from 'history';

const HISTORY_PUSH = 'PUSH';
const HISTORY_POP = 'POP';

export const isScrollRestorationSupported = () => !!(window?.history?.scrollRestoration);

const setScrollRestorationToManual = () => {
  if (isScrollRestorationSupported()) {
    window.history.scrollRestoration = 'manual';
  }
};

const mockedHistory = { push: () => {}, replace: () => {}, listen: () => {} };

export const disableScrollRestoration = () => {
  if (!isScrollRestorationSupported()) {
    require('scroll-restoration-polyfill'); // eslint-disable-line global-require
  }
  setScrollRestorationToManual();
};

export const isStoringHistory = (historyAction) => historyAction === HISTORY_PUSH;

export const isRestoringHistory = (historyAction) => historyAction === HISTORY_POP;

export default typeof window === 'undefined' ? mockedHistory : createBrowserHistory();
