import { onCLS, onFID, onLCP } from 'web-vitals';
import { onINP } from 'web-vitals/attribution';
import { parseConfig } from '../../shared/config';
import { logError } from '../../shared/log';

const isException = (error) => error instanceof Error;

const isString = (value) => typeof value === 'string';

const hasNewRelic = () => typeof window !== 'undefined' && window.newrelic;

const logErrorWithContext = (context, customAttributes) => (handledError) => {
  if (hasNewRelic()) {
    try {
      throw handledError;
    } catch (error) {
      const query = new URLSearchParams(window.location.search).toString();

      window.newrelic.noticeError(error, {
        context,
        query,
        ...customAttributes,
      });

      logError('Error has been reported to NewRelic', error, context);
    }
  }
};

const reportAction = ({ name, value }) => {
  if (hasNewRelic()) window.newrelic.addPageAction('web-vitals', { metricName: name, metricValue: value });
};

export const initialise = () => {
  const { COMMIT } = parseConfig();
  if (hasNewRelic()) window.newrelic.addRelease('listing-ui', COMMIT);

  onCLS(reportAction);
  onFID(reportAction);
  onLCP(reportAction);
  onINP(
    (metric) => {
      if (hasNewRelic()) {
        window.newrelic.addPageAction('INP', {
          interactionToNextPaint: metric.value,
          eventTarget: metric.attribution.eventTarget ?? '',
        });
      }
    },
    { reportAllChanges: true },
  );
};

export default (error, context, customAttributes) => {
  const errorHandlerWithContext = logErrorWithContext(context, customAttributes);

  if (isException(error)) {
    errorHandlerWithContext(error);
  } else if (isString(error)) {
    errorHandlerWithContext(new Error(error));
  } else if (error && error.message) {
    errorHandlerWithContext(new Error(error.message));
  } else if (JSON.stringify) {
    errorHandlerWithContext(new Error(JSON.stringify(error)));
  }
};
