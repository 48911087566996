/* eslint-disable import/no-import-module-exports */
import React from 'react';
import ReactDOM from 'react-dom';
import useragent from 'express-useragent';
import { parseUrl } from '../server/url/urlService';
import router from '../routes/router';
import history, { isRestoringHistory, isStoringHistory } from './service/history';
import ErrorApp from '../routes/app/ErrorApp';
import reportError from './tracking/errorReporter';
import { getCookie } from './cookie/cookieService';
import sanitizeUserAgent from '../shared/model/transformers/sanitizeUserAgent';
import phasedReleases from '../shared/experiments/phasedRelease/config';
import { isBelowThreshold } from '../shared/experiments/phasedRelease/phasedRelease';
import { RECENT_SEARCHES_KEY } from './service/preferenceService';
import ExperimentsRegistry from '../shared/experiments/abTesting/lib/session/registry';
import activeExperiments from '../shared/experiments/abTesting/experiments';
import { scrollPositionManager, scrollToTop } from './service/position';
import tealiumWrapper from '../shared/tracking/tealium/tealiumWrapper';

const getReleases = releases => (
  releases.reduce((acc, { name, percentage }) => {
    const cookieValue = getCookie(name);

    return {
      ...acc,
      [name]: isBelowThreshold(cookieValue, percentage),
    };
  }, {})
);

const scrollManager = scrollPositionManager();

export const storeScrollPositionAndScrollToTop = (historyAction) => {
  if (!isStoringHistory(historyAction)) return;

  scrollManager.savePosition();
  scrollToTop();
};

export const restoreScrollPosition = (historyAction) => {
  if (!isRestoringHistory(historyAction)) return;

  scrollManager.saveAndScroll();
};

const resolveRoute = ({ href, hostname }, container) => {
  const { pathname, search, query } = parseUrl(href, true);
  const parsedUserAgent = useragent.parse(window.navigator.userAgent);
  const location = { pathname, search, query };
  const userAgent = sanitizeUserAgent(parsedUserAgent);
  const experimentsManager = new ExperimentsRegistry(activeExperiments, getCookie);
  const cookies = {
    isSignedIn: !!getCookie('lmdstok'),
    token: getCookie('lmdstok'),
    [RECENT_SEARCHES_KEY]: getCookie(RECENT_SEARCHES_KEY) ? JSON.parse(getCookie(RECENT_SEARCHES_KEY)) : [],
    listingIdToSave: getCookie('listingIdToSave'),
  };
  const releases = getReleases(phasedReleases);
  const hydrateOrRender = module.hot ? ReactDOM.render : ReactDOM.hydrate;

  return router.resolve({
    pathname,
    hostname,
    userAgent,
    location,
    cookies,
    releases,
    experimentsManager,
  })
    .then((Component) => {
      storeScrollPositionAndScrollToTop(history.action);
      hydrateOrRender(Component, container);
      restoreScrollPosition(history.action);
    })
    .catch((error) => {
      reportError(error, 'resolve route error');
      const { status, message, siteSection } = error;
      if (status) {
        const params = { error: { status, message, siteSection }, ...cookies, releases, userAgent };
        hydrateOrRender(<ErrorApp params={params} location={location} />, container);
      }
    })
    .catch(error => reportError(error, 'client failed to handle route error'));
};

const resolveRouteFromLocation = () => resolveRoute(window.location, document.getElementById('wrapper'));

tealiumWrapper.init();

history.listen(resolveRouteFromLocation);

export default resolveRouteFromLocation;
