import times from 'lodash/times';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './ProgressLoader.scss';

class ProgressLoader extends Component {
  state = { longLoad: false };

  componentDidUpdate(prevProps) {
    if (!prevProps.isLoading && this.props.isLoading) {
      this.onStartListener();
    }
    if (prevProps.isLoading && !this.props.isLoading) {
      this.onEndListener();
    }
  }

  onStartListener = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ longLoad: true }); // eslint-disable-line react/no-did-mount-set-state
    }, 300);
  };

  onEndListener = () => {
    clearTimeout(this.timer);
    this.setState({ longLoad: false }); // eslint-disable-line react/no-did-mount-set-state
  };

  render() {
    return (
      <div
        id="AppSpinner"
        className={this.state.longLoad ? styles.appLoaderLoading : styles.appLoader}
      >
        <div className={styles.spinner} role="progressbar">
          { times(11, index => (
            <div className={styles[`line${index}`]} key={`line${index}`}>
              <div className={styles[`lineInsides${index}`]} />
            </div>)) }
        </div>
      </div>
    );
  }
}

ProgressLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.ui.isLoading,
});

export default connect(mapStateToProps)(ProgressLoader);
