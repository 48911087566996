export const breakpoints = {
  breakpointXxs: '360px',
  breakpointXs: '480px',
  breakpointSm: '600px',
  breakpointSmMd: '768px',
  breakpointMd: '960px',
  breakpointLg: '1024px',
  breakpointXl: '1440px',
};

export const color = {
  tealDark: '#00beb3',
  blueDark: '#2b6ed2',
};

export const border = {
  radiusDefault: '3px',
  radiusLarge: '12px',
};

export const font = {
  size: {
    xxs: '10px',
  },
  weight: {
    bold: '700',
  },
};

export const layout = {
  maxWidthDesktopWide: '1200px',
  maxWidthDesktopExtraWide: '1440px',
  maxWidthMainColumn: '712px',
};
