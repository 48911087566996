import pick from 'lodash/pick';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { PHONE_PROP_TYPES } from '../../../routes/detail/model/types';

export const CONTACT_OPTIONS_PROP_TYPES = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  agencyName: PropTypes.string,
  imagePath: PropTypes.string,
  agencyId: PropTypes.string,
  phone: PHONE_PROP_TYPES,
});

export const prepareAgentOption = agency => salesperson => ({
  ...pick(salesperson, ['id', 'name', 'imagePath']),
  agencyName: agency.name,
  agencyId: agency.id,
  value: salesperson.id,
  phone: salesperson.phone,
});

export const prepareAgencyOption = agency => ({
  agencyName: agency.name,
  agencyId: agency.id,
  phone: agency.phone,
});

export const getOptions = (agencies = []) => (
  flatMap(agencies, agency => (
    isEmpty(agency.salespeople)
      ? prepareAgencyOption(agency)
      : agency.salespeople.map(prepareAgentOption(agency))
  ))
);

export const getRandomOption = (agencies) => {
  const agentOptions = getOptions(agencies);
  return agentOptions[Math.floor(Math.random() * agentOptions.length)];
};
