const deformatNetIncome = netIncome => Number(netIncome.toString().replace(/[$,]/g, ''));

export const calculatePrice = (netIncome, yieldOverride, listedPrice, yieldOnlyForUnpricedListings) => {
  if (!yieldOverride) {
    if (listedPrice) {
      return listedPrice;
    }

    return null;
  }

  if (yieldOverride) {
    if (listedPrice && yieldOnlyForUnpricedListings) {
      return listedPrice;
    }

    return deformatNetIncome(netIncome) / (yieldOverride / 100);
  }

  return null;
};

export const calculateYield = (netIncome, yieldOverride, listedPrice, yieldOnlyForUnpricedListings) => {
  if (yieldOverride && yieldOnlyForUnpricedListings) {
    if (listedPrice) {
      return deformatNetIncome(netIncome) / listedPrice * 100;
    }

    return yieldOverride;
  }

  if (yieldOverride && !yieldOnlyForUnpricedListings) {
    return yieldOverride;
  }

  if (listedPrice) {
    return deformatNetIncome(netIncome) / listedPrice * 100;
  }

  return null;
};
