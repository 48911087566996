/* eslint-disable camelcase */
import decodeJwt from 'jwt-decode';
import reportError from '../../client/tracking/errorReporter';

class InvalidLockeToken extends Error {
  constructor(...args) {
    super(...args);

    Error.captureStackTrace(this, InvalidLockeToken);
  }
}

export default (token, errorReporter = reportError) => {
  try {
    const { uid, locke_id } = decodeJwt(token);
    if (!uid || !locke_id) {
      const error = new InvalidLockeToken('Locke token is missing core properties');
      errorReporter(error);
    }
    return {
      uid,
      locke_id,
    };
  } catch (error) {
    return {};
  }
};
