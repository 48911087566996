import PropTypes from 'prop-types';
import React from 'react';
import styles from './RCUIButton.scss';

export const getClasses = (type, size, className) => {
  const typeClass = `${(type !== '') ? styles[type] : styles.default}`;
  const sizeClass = `${(size !== '') ? styles[size] : ''}`;

  return `${typeClass} ${sizeClass} ${className}`;
};

const RCUIButton = ({
  // common
  onClickHandler,
  children,
  classNames = '', // FIXME: Deprecate this in favoour of className
  className = '',
  // button
  type = '',
  size = '',
  disabled,
  ariaLabel,
  // anchor
  href,
  target,
  rel,
}) => {
  const normalisedClassName = className || classNames;

  return (
    href ?
      <a
        className={normalisedClassName}
        onClick={onClickHandler}
        href={href}
        target={target}
        rel={rel}
        aria-label={ariaLabel}
      >
        {children}
      </a> :
      <button
        aria-label={ariaLabel}
        className={getClasses(type, size, normalisedClassName)}
        onClick={onClickHandler}
        disabled={disabled}
        type="button"
      >
        {children}
      </button>
  );
};

RCUIButton.propTypes = {
  // common
  onClickHandler: PropTypes.func.isRequired,
  children: PropTypes.any,
  classNames: PropTypes.string,
  className: PropTypes.string,
  // button
  type: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  // anchor
  href: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

export default RCUIButton;
