import * as actionTypes from './actionTypes';
import { getOptions } from '../../../shared/model/transformers/contactOptions';
import { MAP_VIEW } from '../MapOverlay';
import {
  openEnquiry,
  updateSelectedListing,
  updateSelectedContact,
} from '../../app/actions/creators';

export const openEnquiryForContact = contact => (dispatch) => {
  dispatch(openEnquiry());
  dispatch(updateSelectedContact(contact));
};

export const openCallModal = () => ({
  type: actionTypes.OPEN_CALL_MODAL,
});

export const closeCallModal = () => ({
  type: actionTypes.CLOSE_CALL_MODAL,
});

export const openSocialModal = () => ({
  type: actionTypes.OPEN_SOCIAL_MODAL,
});

export const closeSocialModal = () => ({
  type: actionTypes.CLOSE_SOCIAL_MODAL,
});

const setDetailListing = listing => ({
  type: actionTypes.SET_DETAIL_LISTING,
  listing,
});

const setCurrentChannel = channelObject => ({
  type: actionTypes.SET_CURRENT_CHANNEL,
  channelObject,
});

export const openMediaViewer = (initialItemIndex, mediaType) => ({
  type: actionTypes.OPEN_MEDIA_VIEWER,
  initialItemIndex,
  mediaType,
});

export const closeMediaViewer = () => ({
  type: actionTypes.CLOSE_MEDIA_VIEWER,
});

export const openMapOverlay = (view = MAP_VIEW) => ({
  type: actionTypes.OPEN_MAP_OVERLAY,
  view,
});

export const closeMapOverlay = () => ({
  type: actionTypes.CLOSE_MAP_OVERLAY,
});

export const hydratePdpStore = (listing, currentChannel) => (dispatch) => {
  const {
    product,
    address,
    agencies,
    id,
  } = listing;
  const firstContactOption = getOptions(agencies)[0];
  const selectedListing = {
    product,
    address,
    agencies,
    listingId: id,
    currentChannel,
  };

  dispatch(setDetailListing(listing));
  dispatch(updateSelectedContact(firstContactOption));
  dispatch(setCurrentChannel(currentChannel));
  dispatch(updateSelectedListing(selectedListing));
};
