import { PHASED_RELEASE_UPPER_LIMIT } from './config';

export const calculateThreshold = (upperLimit, percentage) => (upperLimit / 100) * percentage;

export const isBelowThreshold = (cookieValue, percentage) => {
  if (isNaN(cookieValue)) {
    return false;
  }

  const threshold = calculateThreshold(PHASED_RELEASE_UPPER_LIMIT, percentage);

  return cookieValue < threshold;
};
