export const SET_SAVED_LISTINGS = 'SET_SAVED_LISTINGS';
export const SAVE_LISTING = 'SAVE_LISTING';
export const SAVE_LISTING_SUCCESS = 'SAVE_LISTING_SUCCESS';
export const DELETE_LISTING = 'DELETE_LISTING';
export const DELETE_LISTING_TO_SAVE = 'DELETE_LISTING_TO_SAVE';
export const SHOW_SAVE_PROPERTY_OVERLAY = 'SHOW_SAVE_PROPERTY_OVERLAY';
export const HIDE_SAVE_PROPERTY_OVERLAY = 'HIDE_SAVE_PROPERTY_OVERLAY';
export const SHOW_PROPERTY_ALERT_OVERLAY = 'SHOW_PROPERTY_ALERT_OVERLAY';
export const NOT_LOGIN_SAVED_SEARCH = 'NOT_LOGIN_SAVED_SEARCH';
export const CREATE_EMAIL_ALERT = 'CREATE_EMAIL_ALERT';
export const HIDE_PROPERTY_ALERT_OVERLAY = 'HIDE_PROPERTY_ALERT_OVERLAY';
export const SET_LOGIN_STATE = 'SET_LOGIN_STATE';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';

// my yield actions
export const UPDATE_YIELD_REQUEST = 'UPDATE_YIELD_REQUEST';
export const TOGGLE_MODAL_REQUEST = 'TOGGLE_MODAL_REQUEST';

// ui actions
export const TOGGLE_CONTENT_MODAL = 'TOGGLE_CONTENT_MODAL';
export const SET_UI_ELEMENT_DIMENSIONS = 'SET_UI_ELEMENT_DIMENSIONS';
export const SHOW_PROGRESS_LOADER = 'SHOW_PROGRESS_LOADER';
export const HIDE_PROGRESS_LOADER = 'HIDE_PROGRESS_LOADER';
export const SET_HOVERED_LISTING = 'SET_HOVERED_LISTING';

// tool tip actions
export const OPEN_TOOL_TIP = 'OPEN_TOOL_TIP';
export const CLOSE_TOOL_TIP = 'CLOSE_TOOL_TIP';

// invest actions
export const STORE_INVEST_LISTINGS = 'STORE_INVEST_LISTINGS';
export const SORT_INVEST_LISTINGS = 'SORT_INVEST_LISTINGS';
export const RESET_ACTIVE_SORT = 'RESET_ACTIVE_SORT';

// enquiry actions
export const OPEN_ENQUIRY_FORM = 'OPEN_ENQUIRY_FORM';
export const CLOSE_ENQUIRY_FORM = 'CLOSE_ENQUIRY_FORM';
export const UPDATE_SELECTED_LISTING = 'UPDATE_SELECTED_LISTING';
export const UPDATE_SELECTED_CONTACT = 'UPDATE_SELECTED_CONTACT';
export const UPDATE_SELECTED_DESIRED_INFORMATION = 'UPDATE_SELECTED_DESIRED_INFORMATION';
