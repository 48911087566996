const scrollTo = (position) => {
  if (position === undefined || position === null) {
    return;
  }

  window.scrollTo(0, position);
};

const getScrollPosition = () => window.pageYOffset;

export const scrollToTop = () => {
  scrollTo(0);
};

export const scrollPositionManager = () => {
  let position;

  return {
    scrollToSavedPosition() {
      scrollTo(position);
    },
    savePosition() {
      position = getScrollPosition();
    },
    saveAndScroll() {
      const positionBeforeScroll = getScrollPosition();

      scrollTo(position);
      position = positionBeforeScroll;
    },
    getSavedPosition() {
      return position;
    },
  };
};
