import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

export function parseKeywordsUrl(keywordsInUrl?: string): string | undefined {
  if (!keywordsInUrl) return undefined;

  const decodedKeywords = decodeURIComponent(keywordsInUrl);
  const commaSeparatedKeywords = reject(
    decodedKeywords.replace(/\+/g, ' ').split(' '),
    isEmpty,
  ).join(', ');

  return commaSeparatedKeywords;
}
