/* eslint-disable camelcase */
import { getCookie } from '../../../client/cookie/cookieService';
import decodeToken from '../decodeToken';
import { TEALIUM_SCHEMA_USER, CAMPAIGN_TYPE, CAMPAIGN_CHANNEL } from './constants';

export const constructUserData = (decoder = decodeToken) => {
  const token = getCookie('lmdstok');
  const isSignedIn = !!token;
  const anonymousUserId = getCookie('rcauid');
  const countryCode = getCookie('Country');
  const { uid, locke_id } = decoder(token);

  return {
    user: {
      schema: TEALIUM_SCHEMA_USER,
      data: {
        user_login_status: isSignedIn ? 'logged_in' : 'not_logged_in',
        rcauid: anonymousUserId,
        country_code: countryCode,
        my_rca_id: uid,
        locke_id,
      },
    },
  };
};

export const encodeSchema = (schema) => {
  const schemaString = JSON.stringify(schema, null, 2);

  return window?.btoa(schemaString);
};

export const constructCampaignTrackingCode = (
  campaignSource,
  campaignName,
  campaignPlacement,
  campaignContent,
) => {
  const campaignTrackingCode = new URLSearchParams({
    campaignType: CAMPAIGN_TYPE,
    campaignChannel: CAMPAIGN_CHANNEL,
    campaignSource,
    campaignName,
    campaignPlacement,
    campaignContent,
  });

  return `?${campaignTrackingCode.toString()}`;
};
