import querystring from 'querystring';
import { channelObjects, parseUrlChannel } from '../../../shared/model/constants/channels';
import { parseUrlTenureType, tenureTypeObjects } from '../../../shared/model/constants/tenureType';
import searchConditionExtractor from './searchConditionExtractor';
import { parseLocationUrl } from './url/locationUrl';
import { parseKeywordsUrl } from './url/keywordsUrl';
import { getSortOptionByValue } from './query/sortOptions';
import { validateParkingSpaces } from './MinParkingSpaces';
import { getPropertyTypeFromUrl } from '../../../shared/model/constants/propertyTypes';
import type { MapViewPropType } from './query/mapViewOptions';
import { MAP_FULL, MAP_HYBRID } from './query/mapViewOptions';
import reportError from '../../../client/tracking/errorReporter';
import { PAGE_SIZE_FULL_MAP, PAGE_SIZE_HYBRID_MAP, PAGE_SIZE_LIST } from './query/pageSizes';
import type { BoundingBoxType } from '../../../shared/model/constants/filterTypes';
import type {
  Location,
  TenureType,
  SortOptionType,
  AllChannelObjects,
  PropertyType,
} from '../../../shared/model/tsTypes';
import { stripUndefinedProperties } from '../../../shared/stripUndefinedProperties';

export type RefinementsFromQueryType = {
  minPrice?: number;
  maxPrice?: number;
  minFloorArea?: number;
  maxFloorArea?: number;
  maxSiteArea?: number;
  minSiteArea?: number;
  locations?: Location[];
  keywords?: string;
  numParkingSpaces?: number;
  energyEfficiency?: number;
  tenureTypeObject?: TenureType;
  sortObject?: SortOptionType;
  mapView?: MapViewPropType;
  propertyTypes?: PropertyType[];
  page?: string;
  boundingBox?: string;
  includePropertiesWithin?: 'includesurrounding' | 'excludesurrounding';
  searchWithin?: string;
  where?: string;
  currentLocation?: string[];
};

type RefinementsFromPathType = {
  channelObject?: AllChannelObjects;
  locations?: Location[];
  propertyTypes?: PropertyType[];
};

const urlRegexp = new RegExp(
  [
    '^/(for-sale|for-lease|sold|leased|sold-leased|invest)/', // channel
    '([^?]+)?', // path with location, price and prop type
  ].join(''),
);

const sanitiseNumber = (number?: number) => {
  if (!number) {
    return undefined;
  }
  return parseFloat(number.toString()) || undefined;
};

const parseEnergyEfficiency = (energyEfficiency?: string) => {
  if (!energyEfficiency) return undefined;
  return ['1', '2', '3', '4', '5', '6'].includes(energyEfficiency)
    ? Number(energyEfficiency)
    : undefined;
};

const hasPropertyTypes = (propertyTypes?: PropertyType[]) =>
  propertyTypes && propertyTypes.length > 0;

const parsePageNumber = (page?: string, mapViewType?: string) => {
  if (mapViewType === MAP_FULL) {
    return 1;
  }
  const pageAsInteger = page ? parseInt(page, 10) : 1;

  return pageAsInteger > 0 ? pageAsInteger : 1;
};

const parseMapView = (mapView?: MapViewPropType): MapViewPropType | undefined => {
  if (!mapView) return undefined;

  const view = [MAP_FULL, MAP_HYBRID].includes(mapView) ? mapView : undefined;

  return view;
};

const parseBoundingBox = (boundingBox?: string): BoundingBoxType | undefined => {
  if (!boundingBox) return undefined;

  const [northEastLatitude, northEastLongitude, southWestLatitude, southWestLongitude] = boundingBox
    .split(',')
    .map((geoCoordinate) => parseFloat(geoCoordinate));

  if (!northEastLatitude || !northEastLongitude || !southWestLatitude || !southWestLongitude) {
    reportError('Invalid bounding box passed');

    return undefined;
  }

  return {
    northEastLatitude,
    northEastLongitude,
    southWestLatitude,
    southWestLongitude,
  };
};

const parsePageSize = (mapView?: string) => {
  if (!mapView) {
    return PAGE_SIZE_LIST;
  }

  const viewToPageSizesMap = new Map([
    [MAP_HYBRID, PAGE_SIZE_HYBRID_MAP],
    [MAP_FULL, PAGE_SIZE_FULL_MAP],
  ]);

  return viewToPageSizesMap.get(mapView) || PAGE_SIZE_LIST;
};

const parsePropertyTypes = (propertyTypesQueryStr?: string): PropertyType[] | undefined => {
  if (!propertyTypesQueryStr || propertyTypesQueryStr.length === 0) {
    return undefined;
  }

  const propertyTypeStrArray = propertyTypesQueryStr.split(',');

  return propertyTypeStrArray
    .map((propertyUrl) => getPropertyTypeFromUrl(propertyUrl))
    .filter(Boolean);
};

const extractRefinementsFromPath = (relativeUrl: string): RefinementsFromPathType => {
  const matches = relativeUrl.match(urlRegexp);

  if (!matches) {
    return {};
  }
  const [, channelMatch, searchCondition] = matches;
  const channelObject = parseUrlChannel(channelMatch);
  const { propertyTypeObject, locationsUrl } = searchConditionExtractor(searchCondition);
  const locations = parseLocationUrl(locationsUrl);
  const propertyTypes = propertyTypeObject ? [propertyTypeObject] : undefined;

  return {
    channelObject,
    locations,
    propertyTypes,
  };
};

const getMinValue = (min?: number, max?: number) => {
  if (min === undefined) {
    return undefined;
  }

  if (max === undefined) {
    return min;
  }

  return Math.min(min, max);
};

const getMaxValue = (min?: number, max?: number) => {
  if (max === undefined) {
    return undefined;
  }

  if (min === undefined) {
    return max;
  }

  return Math.max(min, max);
};

const extractMinMaxRefinements = (minOrMaxRefinements: RefinementsFromQueryType) => {
  const { minPrice, maxPrice, minSiteArea, maxSiteArea, minFloorArea, maxFloorArea } =
    minOrMaxRefinements;
  const minimumPrice = sanitiseNumber(minPrice);
  const maximumPrice = sanitiseNumber(maxPrice);
  const minimumSiteArea = sanitiseNumber(minSiteArea);
  const maximumSiteArea = sanitiseNumber(maxSiteArea);
  const minimumFloorArea = sanitiseNumber(minFloorArea);
  const maximumFloorArea = sanitiseNumber(maxFloorArea);

  return {
    ...minOrMaxRefinements,
    minPrice: getMinValue(minimumPrice, maximumPrice),
    maxPrice: getMaxValue(minimumPrice, maximumPrice),
    minSiteArea: getMinValue(minimumSiteArea, maximumSiteArea),
    maxSiteArea: getMaxValue(minimumSiteArea, maximumSiteArea),
    minFloorArea: getMinValue(minimumFloorArea, maximumFloorArea),
    maxFloorArea: getMaxValue(minimumFloorArea, maximumFloorArea),
  };
};

const extractRefinementsFromQuery = (queryStr: string): RefinementsFromQueryType => {
  if (!queryStr) {
    return {};
  }

  const refinementsFromQuery = querystring.parse(queryStr);
  const locations = refinementsFromQuery.locations as string | undefined;
  const keywords = refinementsFromQuery.keywords as string | undefined;
  const numParkingSpaces = refinementsFromQuery.numParkingSpaces as string | undefined;
  const energyEfficiency = refinementsFromQuery.energyEfficiency as string | undefined;
  const tenure = refinementsFromQuery.tenure as string | undefined;
  const activeSort = refinementsFromQuery.activeSort as string | undefined;
  const propertyTypes = refinementsFromQuery.propertyTypes as string | undefined;

  return {
    ...refinementsFromQuery,
    ...extractMinMaxRefinements(refinementsFromQuery),
    locations: parseLocationUrl(locations),
    keywords: parseKeywordsUrl(keywords),
    numParkingSpaces: validateParkingSpaces(numParkingSpaces),
    energyEfficiency: parseEnergyEfficiency(energyEfficiency),
    tenureTypeObject: parseUrlTenureType(tenure),
    sortObject: getSortOptionByValue(activeSort),
    propertyTypes: parsePropertyTypes(propertyTypes),
  };
};

const refinementsFromUrl = (relativeUrl: string) => {
  const queryStr = relativeUrl.split('?')[1];
  const {
    channelObject,
    locations: locationsFromPath,
    propertyTypes: propertyTypesFromPath,
  } = extractRefinementsFromPath(relativeUrl) ?? {};

  const {
    minPrice,
    maxPrice,
    minFloorArea,
    maxFloorArea,
    maxSiteArea,
    minSiteArea,
    locations: locationsFromQuery,
    keywords,
    numParkingSpaces,
    energyEfficiency,
    tenureTypeObject,
    sortObject,
    includePropertiesWithin,
    searchWithin,
    mapView: mapViewFromQuery,
    propertyTypes: propertyTypesFromQuery,
    page,
    boundingBox,
    where,
    currentLocation,
  } = extractRefinementsFromQuery(queryStr);
  const mapViewType = parseMapView(mapViewFromQuery);
  const cleanRefinements = stripUndefinedProperties({
    channelObject: channelObject || channelObjects.ForSale,
    minPrice,
    maxPrice,
    minFloorArea,
    maxFloorArea,
    maxSiteArea,
    minSiteArea,
    keywords,
    numParkingSpaces,
    energyEfficiency,
    tenureTypeObject: tenureTypeObject || tenureTypeObjects.ANY,
    sortObject,
    includePropertiesWithin,
    searchWithin,
    where,
    currentLocation,
    localities: locationsFromQuery || locationsFromPath,
    propertyTypes: hasPropertyTypes(propertyTypesFromPath)
      ? propertyTypesFromPath
      : propertyTypesFromQuery,
    page: parsePageNumber(page, mapViewType),
    mapView: mapViewType,
    boundingBox: parseBoundingBox(boundingBox),
    pageSize: parsePageSize(mapViewType),
  });

  return cleanRefinements;
};

export default refinementsFromUrl;
