import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import AppComponent from './AppComponent';
import MetaData from '../MetaData';
import Schema from '../Schema';
import ProgressLoader from './ProgressLoader';
import ErrorHandler, { ERROR_PROP_TYPES } from '../error/ErrorContainer';
import '../../../styles/main.scss';
import { generateStore } from './globalStore';
import { UserAgentProvider } from '../../shared/userAgentContext';
import { ReleaseProvider } from '../../shared/releaseContext';
import { USER_AGENT_PROP_TYPES, HEADER_PROP_TYPE } from '../../shared/model/types';
import { RELEASE_PROP_TYPES } from '../../shared/experiments/phasedRelease/config';
import { EXPERIMENTS_REGISTRY_PROP_TYPE } from '../../shared/experiments/abTesting/lib/types';
import { MAP_VIEW_PROP_TYPES } from '../searchResult/model/query/mapViewOptions';
import ExperimentsContext from '../../shared/experiments/abTesting/lib/ExperimentContext';
import { MediaProvider } from '../../shared/useMedia';
import { REFINEMENT_PROP_TYPES } from '../searchResult/model/types';

const AppContainer = ({
  children,
  metaData,
  schema = () => {},
  error,
  storeData = {},
  isSignedIn,
  token,
  headerProps,
  userAgent,
  releases,
  listingIdToSave,
  experimentsManager,
  mapView,
  stickyHeader,
}) => (
  <ExperimentsContext.Provider value={experimentsManager}>
    <Provider store={generateStore(storeData, isSignedIn, token, listingIdToSave)}>
      <UserAgentProvider userAgent={userAgent}>
        <ReleaseProvider releases={releases}>
          <MediaProvider>
            {error ? (
              <AppComponent headerProps={headerProps} mapView={mapView}>
                <ErrorHandler
                  className="has-error"
                  status={error.status}
                  message={error.message}
                  siteSection={error.siteSection}
                />
              </AppComponent>
            ) : (
              <AppComponent headerProps={headerProps} mapView={mapView} stickyHeader={stickyHeader}>
                <MetaData {...metaData()} />
                <Schema {...schema()} />
                <ProgressLoader />
                {children}
              </AppComponent>
            )}
          </MediaProvider>
        </ReleaseProvider>
      </UserAgentProvider>
    </Provider>
  </ExperimentsContext.Provider>
);

AppContainer.propTypes = {
  metaData: PropTypes.func,
  schema: PropTypes.func,
  error: PropTypes.shape(ERROR_PROP_TYPES),
  children: PropTypes.node.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  token: PropTypes.string,
  storeData: PropTypes.shape({
    refinements: PropTypes.shape({
      filters: REFINEMENT_PROP_TYPES,
      isOpen: PropTypes.bool,
    }),
  }),
  headerProps: PropTypes.shape(HEADER_PROP_TYPE),
  userAgent: USER_AGENT_PROP_TYPES,
  releases: RELEASE_PROP_TYPES,
  listingIdToSave: PropTypes.string,
  experimentsManager: EXPERIMENTS_REGISTRY_PROP_TYPE,
  mapView: MAP_VIEW_PROP_TYPES,
  stickyHeader: PropTypes.bool,
};

export default AppContainer;
