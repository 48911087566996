import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Icon from './Icon/index';
import styles from './RCUIOverlay.scss';

export const Mask = props => <div className={styles.mask} {...props} />;

class RCUIOverlay extends Component {
  constructor() {
    super();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleScroll = this.toggleScroll.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
    if (!this.props.allowScroll) {
      this.toggleScroll(true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
    this.toggleScroll(false);
  }

  handleKeyPress(event) {
    const key = event.which || event.keyCode;
    if (key === 27) { // Escape
      this.props.closeFn();
    }
  }

  toggleScroll(isToggled) { // eslint-disable-line
    const html = document.getElementsByTagName('html')[0];
    return isToggled
      ? html.classList.add(styles.noScroll)
      : html.classList.remove(styles.noScroll);
  }

  render() {
    const {
      id,
      isOpen,
      children,
      closeFn,
      overlayClasses,
      closeButtonClasses,
      hideCloseButton,
      parentRef,
    } = this.props;

    const overlayClassNames = [overlayClasses, styles.overlay].join(' ');
    const closeButtonClassNames = [closeButtonClasses, styles.closeButton].join(' ');
    const visibilityClassName = isOpen ? styles.show : styles.hide;

    return (
      <div id={id} className={visibilityClassName} onKeyDown={this.handleKeyPress}>
        <div tabIndex={-1} ref={parentRef} className={overlayClassNames}>
          {!hideCloseButton &&
          <button tabIndex={0} aria-label="close" className={closeButtonClassNames} onClick={closeFn}>
            <Icon name="cross" className={styles.closeIcon} />
          </button>}
          {children}
        </div>
        <Mask onClick={closeFn} />
      </div>
    );
  }
}

RCUIOverlay.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  closeFn: PropTypes.func,
  overlayClasses: PropTypes.string,
  closeButtonClasses: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  allowScroll: PropTypes.bool,
  parentRef: PropTypes.func,
};

RCUIOverlay.defaultPropTypes = {
  id: '',
};

export default RCUIOverlay;
