import React from 'react';
import PropTypes from 'prop-types';
import { CommercialKit } from '@realcommercial.com.au/commercial-kit';
import { Pangea } from '@rea-group/construct-kit-core';
import Footer from '@realcommercial.com.au/footer';
import { Header } from '@realcommercial.com.au/header';
import ErrorBoundary from './ErrorBoundary';
import type { MapViewPropType } from '../searchResult/model/query/mapViewOptions';
import { MAP_VIEW_OPTIONS, MAP_VIEW_PROP_TYPES } from '../searchResult/model/query/mapViewOptions';
import { parseConfig } from '../../shared/config';
import { HEADER_PROP_TYPE } from '../../shared/model/types';
import styles from './AppComponent.scss';

const { LOCKE_CLIENT_ID } = parseConfig() as any;

type Props = {
  children: React.ReactNode;
  mapView?: MapViewPropType;
  headerProps: {
    pathname: string;
    search: string;
  };
  stickyHeader: boolean;
};

const AppComponent = ({
  children,
  headerProps,
  mapView,
  stickyHeader,
}: Props): React.ReactElement => {
  const hideDivider = headerProps?.pathname === '/' || headerProps?.pathname === '/find-agent/';
  return (
    <CommercialKit>
      <Pangea />
      <div className={styles.normalBody}>
        <div className={`${styles.headerWrapper} ${stickyHeader ? styles.sticky : ''}`}>
          <Header
            currentPage={`${headerProps?.pathname}${headerProps?.search}`}
            lockeClientId={LOCKE_CLIENT_ID}
            fullWidth={mapView && MAP_VIEW_OPTIONS.includes(mapView)}
            hideDivider={hideDivider}
          />
        </div>
        <ErrorBoundary>{children}</ErrorBoundary>
        <div className={styles.footerWrapper}>{!mapView && <Footer />}</div>
      </div>
    </CommercialKit>
  );
};

AppComponent.propTypes = {
  children: PropTypes.node,
  mapView: MAP_VIEW_PROP_TYPES,
  headerProps: PropTypes.shape(HEADER_PROP_TYPE),
  stickyHeader: PropTypes.bool,
};

export default AppComponent;
