import map from 'lodash/map';
import omit from 'lodash/omit';
import flow from 'lodash/flow';
import { initialInvestState } from '../../app/reducers/invest';
import { RELEVANCY } from './query/sortOptions';

const translateDate = listings => map(listings, listing => (
  omit({
    ...listing,
    date: listing.dateActive,
  }, 'dateActive')
));

const withListedPrice = listings => map(listings, listing => ({
  ...listing,
  listedPrice: listing.listedPrice || listing.price,
}));

const withRelevancy = listings => map(listings, (listing, i) => ({
  ...listing,
  [RELEVANCY]: i,
}));

export const translateInvestListings = flow(withRelevancy, withListedPrice, translateDate);

export const prepareInvestStore = data => ({
  invest: {
    ...initialInvestState,
    listings: translateInvestListings(data.listings),
  },
});
