import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
import { RELEASE_PROP_TYPES, prepareFeatureFlags } from './experiments/phasedRelease/config';

const { Provider, Consumer } = createContext();

export const ReleaseProvider = props => (
  <Provider
    value={props.releases}
  >
    {props.children}
  </Provider>
);

ReleaseProvider.propTypes = {
  releases: RELEASE_PROP_TYPES.isRequired,
  children: PropTypes.node,
};

const filteredReleases = (available = {}, selected = []) => { // eslint-disable-line arrow-body-style
  const filtered = pickBy(available, (_value, key) => includes(selected, key));
  const missingOptions = difference(selected, Object.keys(filtered));

  if (missingOptions.length) throw new Error(`Selected aspects are missing: ${missingOptions}`);

  return filtered;
};

export const withRelease = (selectedReleases = []) => WrappedComponent => props => (
  <Consumer>
    {
      (availableReleases) => {
        const releases = filteredReleases(availableReleases, selectedReleases);
        const featureFlags = prepareFeatureFlags(availableReleases);
        return (
          <WrappedComponent
            {...props}
            {...releases}
            featureFlags={featureFlags}
          />
        );
      }
    }
  </Consumer>
);

export default Consumer;
