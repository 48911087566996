import fetch from 'isomorphic-fetch';
import reportError from '../client/tracking/errorReporter';

function apiError(response) {
  // TODO: this error is not very useful right now, it would be better to call response.text() and add the result of the promise to the error
  const error = new Error(`status: ${response.status} url: ${response.url}`);
  error.status = response.status || 500;
  return error;
}

function isJson(response) {
  const contentType = response.headers.get('content-type');
  return contentType && /^application\/.*json.*/.test(contentType);
}

function handleErrors(response) {
  if (!response.ok) {
    return Promise.reject(apiError(response));
  }
  return response;
}

function handleResponse(response) {
  return (isJson(response) ? response.json() : response.text());
}

const httpService = {};

httpService.wrappedFetch = (path, options) => fetch(path, options);

httpService.request = ({ method, header, body, path, credentials }) => {
  const options = { method };

  if (header !== undefined && header !== null) {
    options.headers = header;
  }

  if (body) {
    options.body = JSON.stringify(body);
  }

  if (credentials) {
    options.credentials = credentials;
  }

  return httpService.wrappedFetch(path, options)
    .then(handleErrors)
    .then(handleResponse, (error) => {
      reportError(error);
      throw error;
    });
};

export default httpService;
