import find from 'lodash/find';
import type { FilterOptionType } from './filterTypes';

export type AllChannelTypes = ChannelType | InvestType;

export type ChannelType = {
  id: string;
  price: string;
  omniture: string;
  tealium: string;
  campaign: string;
  krux: string;
  url: string;
  adArea: string;
  shortHumanReadable: string;
  displayInSelect: string;
  humanReadable: string;
  titleVariant: string;
  title: string;
};

type InvestType = Omit<ChannelType, 'adArea' | 'campaign'>;

export const ForSale: ChannelType = {
  id: 'buy',
  price: 'forSale',
  omniture: 'for sale',
  tealium: 'for sale',
  campaign: 'buy_srp',
  krux: 'buy',
  url: 'for-sale',
  adArea: 'buy',
  shortHumanReadable: 'Buy',
  displayInSelect: 'Buy',
  humanReadable: 'For Sale',
  titleVariant: 'for Sale',
  title: 'Commercial Real Estate & Commercial Property For Sale and For Lease',
} as const;

export const ForLease: ChannelType = {
  id: 'rent',
  price: 'forLease',
  omniture: 'for lease',
  tealium: 'for lease',
  campaign: 'lease_srp',
  krux: 'lease',
  url: 'for-lease',
  adArea: 'lease',
  shortHumanReadable: 'Lease',
  displayInSelect: 'Lease',
  humanReadable: 'For Lease',
  titleVariant: 'for Lease',
  title: 'Commercial Real Estate & Commercial Property For Lease',
} as const;

export const Sold: ChannelType = {
  id: 'sold',
  price: 'sold',
  omniture: 'sold',
  tealium: 'sold',
  campaign: 'sold_srp',
  krux: 'sold',
  url: 'sold',
  adArea: 'sold',
  shortHumanReadable: 'Sold',
  displayInSelect: 'Sold',
  humanReadable: 'Sold',
  titleVariant: 'Sold',
  title: 'Sold Commercial Property - realcommercial.com.au',
} as const;

export const Leased: ChannelType = {
  id: 'leased',
  price: 'leased',
  omniture: 'leased',
  tealium: 'leased',
  campaign: 'leased_srp',
  krux: 'leased',
  url: 'leased',
  adArea: 'leased',
  shortHumanReadable: 'Leased',
  displayInSelect: 'Leased',
  humanReadable: 'Leased',
  titleVariant: 'Leased',
  title: 'Leased Commercial Property - realcommercial.com.au',
} as const;

export const Invest: InvestType = {
  id: 'invest',
  price: 'invest',
  omniture: 'invest',
  tealium: 'invest',
  krux: 'invest',
  url: 'invest',
  displayInSelect: 'Invest',
  humanReadable: 'Investment',
  shortHumanReadable: 'Invest',
  titleVariant: 'for Investment',
  title: 'Commercial Investment Property - realcommercial.com.au',
} as const;

export const channelObjects = {
  ForSale,
  ForLease,
  Invest,
  Sold,
  Leased,
};

export const channelsArray = [ForSale, ForLease, Sold, Leased];

export const channelForSelectElement = channelsArray.map(
  ({ id, shortHumanReadable }): FilterOptionType => ({
    value: id,
    label: shortHumanReadable,
  }),
);

export const getChannelObjectFromChannelId = (
  channelId: ChannelType['id'],
): InvestType | ChannelType => channelsArray.find((channel) => channelId === channel.id) || ForSale;

export function channelFromUrl(urlChannel: string) {
  return find(channelObjects, (ch) => ch.url === urlChannel);
}

export function channelIdFromUrl(urlString = '') {
  const channel = channelFromUrl(urlString);
  return channel && channel.id;
}

class ChannelNotFound extends Error {
  constructor(channel: string) {
    super();
    this.message = `the channel '${channel}' was not found`;
  }
}

export function parseUrlChannel(urlChannel: string) {
  const channelObject = channelFromUrl(urlChannel);
  if (channelObject) {
    return channelObject;
  }
  throw new ChannelNotFound(urlChannel);
}

export const isSoldOrLeased = (channelObject: AllChannelTypes) =>
  [Sold.id, Leased.id].includes(channelObject.id);
