import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';

class MetaData extends Component {
  static serverSideRender(renderToString, title, meta, link) {
    renderToString(
      <MetaData title={title} meta={meta} link={link} />,
    );

    const head = Helmet.rewind();

    return [
      head.title.toString(),
      head.meta.toString(),
      head.link.toString(),
    ].join('\n  ');
  }

  render() {
    const { title, meta, link } = this.props;
    return (
      <Helmet title={title} meta={meta} link={link} />
    );
  }
}

MetaData.displayName = 'MetaData';

MetaData.propTypes = {
  title: PropTypes.string.isRequired,
  meta: PropTypes.array,
  link: PropTypes.array,
};

MetaData.defaultProps = {
  meta: [],
  link: [],
};

export default MetaData;
