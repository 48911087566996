import sample from 'lodash/sample';
import includes from 'lodash/includes';

const FOUR_WEEKS = 4 * 7 * 24 * 60 * 60 * 1000;

export const createExperimentSession = (experimentName, variants) => (req, res) => {
  const sessionVariant = req.cookies[experimentName];

  if (includes(variants, sessionVariant)) {
    return sessionVariant;
  }

  const variant = sample(variants);
  res.cookie(experimentName, variant, { expires: new Date(Date.now() + FOUR_WEEKS) });
  return variant;
};
