import PropTypes from 'prop-types';
import type { TenureType } from '../tsTypes';

const VACANT: TenureType = {
  key: 'vacant',
  url: 'vacant',
  bff: 'vacant',
  omniture: 'vacant possession',
  displayText: 'Vacant Possession',
};

const TENANTED: TenureType = {
  key: 'tenanted',
  url: 'tenanted',
  bff: 'tenanted',
  omniture: 'tenanted investment',
  displayText: 'Tenanted Investment',
};

const ANY: TenureType = {
  key: 'any',
  url: undefined,
  bff: undefined,
  omniture: 'any',
  displayText: 'Any',
};

export const tenureTypeObjects = {
  ANY,
  VACANT,
  TENANTED,
};

export const tenureTypeArray: Array<TenureType> = [ANY, VACANT, TENANTED];

export const parseUrlTenureType = (urlTenureType: string | undefined): TenureType => {
  if (urlTenureType) {
    const urlTenureTypeResult = tenureTypeArray.find(
      (tenureType) => tenureType.url === urlTenureType,
    );
    return urlTenureTypeResult || ANY;
  }
  return ANY;
};

export const getTenureTypeByKey = (key: string | undefined): TenureType =>
  tenureTypeArray.find((tenureType) => tenureType.key === key) || ANY;

export const TENURE_TYPE_OBJECT_PROP_TYPES = PropTypes.shape({
  key: PropTypes.string.isRequired,
  url: PropTypes.string,
  bff: PropTypes.string,
  omniture: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
});
