import React, { Component } from 'react';
import PropTypes from 'prop-types';
import reportError from '../../client/tracking/errorReporter';
import ErrorHandler from '../error/ErrorContainer';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });

    reportError(error, info);
  }

  render() {
    return this.state.hasError ? <ErrorHandler status={400} /> : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
