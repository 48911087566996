import { createExperimentSession } from './session';

class ExperimentsRegistry {
  experiments;

  sessionVariants;

  constructor(experiments = [], getCookieByName) {
    this.experiments = experiments;

    if (getCookieByName) {
      this.sessionVariants = this.getAllSessions(getCookieByName);
    }
  }

  createExperimentSessionsAndStore = (req, res) => {
    const sessions = {};
    this.experiments.forEach(({ experimentName, variants }) => {
      const sessionVariant = createExperimentSession(experimentName, variants)(req, res);
      if (sessionVariant) {
        sessions[experimentName] = sessionVariant;
      }
    });
    this.sessionVariants = sessions;
  };

  getAllSessions = (getCookieByName) => {
    const sessions = {};
    this.experiments.forEach((experiment) => {
      const sessionVariant = getCookieByName(experiment.experimentName);
      if (sessionVariant) {
        sessions[experiment.experimentName] = sessionVariant;
      }
    });
    return sessions;
  };

  isActiveVariant = (experimentName, variantName, isDefaultVariant) => {
    const sessionVariantName = this.sessionVariants && this.sessionVariants[experimentName];

    if (!sessionVariantName) {
      return isDefaultVariant;
    }

    return sessionVariantName === variantName;
  };

  experimentForTracking = (experiment) => {
    const { experimentName, section } = experiment;
    const variant = this.sessionVariants && this.sessionVariants[experimentName];
    if (!variant) {
      return undefined;
    }

    return {
      experiment_id: experimentName,
      variant,
      section,
    };
  };

  getAllExperimentsForTracking = () => {
    if (!this.sessionVariants) {
      return undefined;
    }

    const experimentsData = this.experiments.reduce((experiments, experiment) => {
      const maybeExperiment = this.experimentForTracking(experiment);
      const experimentForTracking = maybeExperiment ? [maybeExperiment] : [];

      return [...experiments, ...experimentForTracking];
    }, []);

    return experimentsData.length !== 0 ? experimentsData : undefined;
  };
}

export default ExperimentsRegistry;
