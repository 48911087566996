const logFactory = (consoleFunction, severity) => (message, error, ...information) => {
  const errorObject = error && error.message && error.stack
    ? { message: error.message, stack: error.stack }
    : { withoutStackTrace: error };
  const logObject = {
    timestamp: new Date(),
    severity,
    message,
    error: errorObject,
    additionalInfo: information,
  };
  const stringOrObject = typeof window === 'undefined' ? JSON.stringify(logObject) : logObject;
  consoleFunction(stringOrObject);
};

/* eslint-disable no-console */
export const logError = logFactory(console.error, 'ERROR');
export const logWarn = logFactory(console.warn, 'WARNING');
export const logInfo = logFactory(console.info, 'INFO');
