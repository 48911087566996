export const OPEN_CALL_MODAL = 'OPEN_CALL_MODAL';
export const CLOSE_CALL_MODAL = 'CLOSE_CALL_MODAL';
export const OPEN_SOCIAL_MODAL = 'OPEN_SOCIAL_MODAL';
export const CLOSE_SOCIAL_MODAL = 'CLOSE_SOCIAL_MODAL';
export const SET_DETAIL_LISTING = 'SET_DETAIL_LISTING';
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
export const OPEN_MEDIA_VIEWER = 'OPEN_MEDIA_VIEWER';
export const CLOSE_MEDIA_VIEWER = 'CLOSE_MEDIA_VIEWER';
export const OPEN_MAP_OVERLAY = 'OPEN_MAP_OVERLAY';
export const CLOSE_MAP_OVERLAY = 'CLOSE_MAP_OVERLAY';
