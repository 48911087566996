import type { FilterOptionType } from './filterTypes';
import type { PropertyType } from '../tsTypes';
// FIXME: Remove oldUrl when we no longer depend on it

export const OFFICES: PropertyType = {
  id: 'offices',
  url: 'offices',
  oldUrl: 'offices',
  agencyUrl: 'offices',
  marketing: 'offices',
  displayText: 'Offices',
  longDisplayText: 'Offices',
  sentenceDisplayText: 'Offices',
  pdpTitle: 'Office',
  iconName: 'offices',
};

export const RETAIL: PropertyType = {
  id: 'retail',
  url: 'retail',
  oldUrl: 'retail',
  agencyUrl: 'retail',
  marketing: 'retail',
  displayText: 'Retail',
  longDisplayText: 'Shops & Retail',
  sentenceDisplayText: 'Shop & Retail Property',
  pdpTitle: 'Shop & Retail Property',
  iconName: 'retail',
};

export const INDUSTRIAL_WAREHOUSE: PropertyType = {
  id: 'industrial warehouse',
  url: 'industrial-warehouse',
  oldUrl: 'industrial+warehouse',
  agencyUrl: 'industrial-warehouse',
  marketing: 'industrial_warehouse',
  displayText: 'Industrial/Warehouse',
  longDisplayText: 'Warehouse, Factory & Industrial',
  sentenceDisplayText: 'Warehouse, Factory & Industrial Property',
  pdpTitle: 'Industrial & Warehouse Property',
  iconName: 'industrial',
};

export const SHOWROOMS_BULKY_GOODS: PropertyType = {
  id: 'showrooms bulky goods',
  url: 'showrooms-bulky-goods',
  oldUrl: 'showrooms+bulky+goods',
  agencyUrl: 'showrooms-bulky-goods',
  marketing: 'showrooms_bulky_goods',
  displayText: 'Showrooms/Bulky Goods',
  longDisplayText: 'Showrooms & Large Format Retail',
  sentenceDisplayText: 'Showrooms & Large Format Retail Property',
  pdpTitle: 'Showroom & Large Format Retail',
  iconName: 'showrooms',
};

export const LAND_DEVELOPMENT: PropertyType = {
  id: 'land development',
  url: 'land-development',
  oldUrl: 'land+development',
  agencyUrl: 'land-development',
  marketing: 'land_development',
  displayText: 'Land/Development',
  longDisplayText: 'Development Sites & Land',
  sentenceDisplayText: 'Development Sites & Land',
  pdpTitle: 'Development Site & Land',
  iconName: 'landDevelopment',
};

export const HOTEL_LEISURE: PropertyType = {
  id: 'hotel leisure',
  url: 'hotel-motel-leisure',
  oldUrl: 'hotel+leisure',
  agencyUrl: 'hotel-leisure',
  marketing: 'hotel_leisure',
  displayText: 'Hotel/Leisure',
  longDisplayText: 'Hotel, Motel & Leisure',
  sentenceDisplayText: 'Hotel, Motel & Leisure Property',
  pdpTitle: 'Hotel, Motel & Leisure Property',
  iconName: 'hotel',
};

export const MEDICAL_CONSULTING: PropertyType = {
  id: 'medical consulting',
  url: 'medical-consulting',
  oldUrl: 'medical+consulting',
  agencyUrl: 'medical-consulting',
  marketing: 'medical_consulting',
  displayText: 'Medical/Consulting',
  longDisplayText: 'Medical & Consulting',
  sentenceDisplayText: 'Medical & Consulting Property',
  pdpTitle: 'Medical & Consulting Property',
  iconName: 'medical',
};

export const COMMERCIAL_FARMING: PropertyType = {
  id: 'commercial farming',
  url: 'commercial-farming',
  oldUrl: 'commercial+farming',
  agencyUrl: 'commercial-farming',
  marketing: 'commercial_farming',
  displayText: 'Commercial Farming',
  longDisplayText: 'Commercial Farming & Rural',
  sentenceDisplayText: 'Commercial Farming & Rural Property',
  pdpTitle: 'Commercial Farming & Rural Property',
  iconName: 'commercialFarming',
};

export const OTHER: PropertyType = {
  id: 'other',
  url: 'other',
  oldUrl: 'other',
  agencyUrl: 'other',
  marketing: 'other',
  displayText: 'Other',
  longDisplayText: 'Other',
  sentenceDisplayText: 'Other Property',
  pdpTitle: 'Other Property',
  iconName: 'other',
};

export const allPropertyTypes: PropertyType[] = [
  INDUSTRIAL_WAREHOUSE,
  RETAIL,
  OFFICES,
  LAND_DEVELOPMENT,
  SHOWROOMS_BULKY_GOODS,
  HOTEL_LEISURE,
  MEDICAL_CONSULTING,
  COMMERCIAL_FARMING,
  OTHER,
];

export const DEFAULT_SELECT_PROPERTY_OPTION: FilterOptionType = {
  value: 'all',
  label: 'All property types',
};

export const propertyTypesForSelectElement: FilterOptionType[] = allPropertyTypes.map(
  (propertyType) => ({
    value: propertyType.id,
    label: propertyType.longDisplayText,
  }),
);

export const getPropertyTypeFromUrl = (propertyTypeUrl: string): PropertyType | undefined =>
  allPropertyTypes.find((p) => p.url === propertyTypeUrl);

export const getPropertyTypeFromId = (propertyTypeId: string): PropertyType | undefined =>
  allPropertyTypes.find((p) => p.id === propertyTypeId);

export const getPropertyTypesFromIds = (propertyTypes: string[]): PropertyType[] =>
  allPropertyTypes.filter((propertyType) => propertyTypes.includes(propertyType.id));

export const getPropertyTypeFromDisplayText = (displayText: string): PropertyType | undefined =>
  allPropertyTypes.find((p) => p.displayText === displayText);

export const hasOnePropertyType = (propertyTypes: PropertyType[]) => propertyTypes.length === 1;
