import refinementsFromUrl from './refinementsFromUrl';
import queryFromRefinements from './queryFromRefinements';

export const getQueryFromUrlString = (url) => {
  const urlParsed = new URL(url, window.location);
  const { pathname, search } = urlParsed;
  const refinements = refinementsFromUrl(pathname + search);

  return queryFromRefinements(refinements);
};

export default {
  getQueryFromUrl() {
    const { search, pathname } = window.location;
    const refinements = refinementsFromUrl(pathname + search);

    return queryFromRefinements(refinements);
  },
};
