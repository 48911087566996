import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import difference from 'lodash/difference';
import { USER_AGENT_PROP_TYPES } from './model/types';

const { Provider, Consumer } = createContext();

export function UserAgentProvider(props) {
  return (
    <Provider
      value={props.userAgent}
    >
      {props.children}
    </Provider>
  );
}

UserAgentProvider.propTypes = {
  userAgent: USER_AGENT_PROP_TYPES.isRequired,
  children: PropTypes.node,
};

const filteredAspects = (available = {}, selected = []) => { // eslint-disable-line arrow-body-style
  const filtered = pickBy(available, (_value, key) => includes(selected, key));
  const missingAspects = difference(selected, Object.keys(filtered));

  if (missingAspects.length) throw new Error(`Selected aspects are missing: ${missingAspects}`);

  return filtered;
};

export const withUserAgent = selectedAspects => WrappedComponent => function (props) {
  return (
    <Consumer>
      {
      (userAgent) => {
        const userAgentAspects = selectedAspects ? filteredAspects(userAgent, selectedAspects) : { userAgent };

        return (
          <WrappedComponent
            {...props}
            {...userAgentAspects}
          />
        );
      }
    }
    </Consumer>
  );
};

export default Consumer;
