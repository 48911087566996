import PropTypes from 'prop-types';
import { Component } from 'react';

export const isClient = () => typeof window !== 'undefined';

export const clientOnlyCall = (clientFunction) => (args) => {
  if (isClient()) {
    clientFunction(args);
  }
};

export class ClientOnlyRender extends Component {
  state = {
    isClient: false,
  };

  componentDidMount() {
    this.setState({ isClient: true });
  }

  render() {
    return this.state.isClient ? this.props.children : null;
  }
}

ClientOnlyRender.propTypes = {
  children: PropTypes.node.isRequired,
};
