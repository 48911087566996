import PropTypes from 'prop-types';
import React from 'react';
import trackingAdapter from './tracking/trackingAdapter';
import { parseConfig } from '../../shared/config';
import { scrollToTop } from '../../client/service/position';
import styles from './Message.scss';

export default class extends React.Component {
  static propTypes = {
    // TODO should it be number or string?
    status: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    message: PropTypes.string,
    siteSection: PropTypes.string,
  };

  componentDidMount() {
    scrollToTop();
    this.handlePageTracking();
  }

  handlePageTracking = () => {
    if (this.props.status == 404) {
      trackingAdapter.trackNotFoundPageEvent({ message: this.props.message, siteSection: this.props.siteSection });
    }
  };

  renderMessage = (status) => {
    if (status == '404') {
      return (
        <div>
          <h1 className={styles.reminderBoxTitle}>Looking for Something?</h1>

          <p className={styles.reminderBoxContent}>The page you requested could not be found.</p>
          <p className={styles.reminderBoxContent}>If you typed the URL yourself, please check your spelling. If you followed a link, it may be out of date.</p>
        </div>
      );
    }
    return (
      <div>
        <h1 className={styles.reminderBoxTitle}>OOPS</h1>
        <p className={styles.reminderBoxContent}>
          We're having a commercial break right now.
          Yes, we could work on our humour, but we're better at fixing technical issues like this one.
          We should be back to normal services soon.
        </p>
      </div>
    );
  };

  render() {
    const websiteRoot = parseConfig().WEBSITE_ROOT;

    return (
      <section className={styles.reminderBoxContainer}>
        <img alt="error" src="/listing-ui-assets/img/oops.png" />
        {this.renderMessage(this.props.status)}
        <p className={styles.reminderBoxContent}>You can return to the home page or search:</p>
        <a href="/" className={styles.reminderBoxButton}>New search</a>
        <ul>
          <li><a href={`${websiteRoot}/news`} title="Read the latest commercial property news"><span>News</span></a></li>
        </ul>
      </section>
    );
  }
}
