/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RCUIOverlay from '../../shared/components/RCUIOverlay';
import styles from './MapOverlay.scss';
import { closeMapOverlay } from './reducer/creators';
import { MAP_PROP_TYPES, ADDRESS_PROP_TYPES } from './model/types';
import MapControls from './MapControls';
import { STREET } from '../../shared/model/constants/precision';
import { buildMarker } from './DynamicMap';
import { googleMapLoader } from '../../shared/loadMapScript';

export const MAP_VIEW = 'MAP_VIEW';
export const STREET_VIEW = 'STREET_VIEW';

export class MapOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGoogleMapsCreated: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen, currentView } = this.props;
    const willOpen = !isOpen && nextProps.isOpen;
    const viewChanged = currentView !== nextProps.currentView;

    if (willOpen || viewChanged) {
      this.loadLibraryAndRender(nextProps.currentView);
    }
  }

  componentDidUpdate() {
    if (this.streetViewElement) {
      // Fixes a bug with streetview rendering in a modal source: https://stackoverflow.com/questions/45654166/reuse-a-google-maps-street-view-inside-of-a-modal
      google.maps.event.trigger(this.streetViewElement, 'resize');
    }
  }

  componentWillUnmount() {
    this.props.close();
  }

  loadLibraryAndRender = (view) => {
    googleMapLoader()
      .importLibrary('maps')
      .then(() => {
        if (view === STREET_VIEW) {
          this.renderStreetView();
          return;
        }
        this.renderMap();
      });

    this.overlayElement.focus();
  };

  renderMap = () => {
    this.props.trackMapViewClick();

    if (!this.state.isGoogleMapsCreated) {
      const { lat, lng, zoomLevel, precision } = this.props.map;
      const shouldDropMarker = precision === STREET;

      const map = new google.maps.Map(this.mapContainerElement, {
        center: {
          lat,
          lng,
        },
        zoom: zoomLevel,
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: 'greedy',
      });

      if (shouldDropMarker) buildMarker({ mapRef: map, lat, lng });

      this.setState({ isGoogleMapsCreated: true });
    }
  };

  renderStreetView = () => {
    this.props.trackStreetViewClick();

    if (!this.streetViewElement) {
      const { lat, lng } = this.props.map;

      this.streetViewElement = new google.maps.StreetViewPanorama(this.streetViewContainerElement, {
        // eslint-disable-line no-new
        position: { lat, lng },
        fullscreenControl: false,
        addressControl: false,
        pov: {
          heading: 90,
          pitch: 10,
        },
      });
    }
  };

  render() {
    const { isOpen, close, trackGetDirectionsClick, currentView } = this.props;
    const { streetAddress, suburbAddress } = this.props.address;
    const title = `${streetAddress} ${suburbAddress}`;
    const { precision, lat, lng } = this.props.map;
    const hasFullAddress = precision === STREET;

    return (
      <RCUIOverlay
        isOpen={isOpen}
        closeFn={close}
        overlayClasses={styles.overlay}
        closeButtonClasses={styles.closeButton}
        parentRef={(ref) => (this.overlayElement = ref)}
        allowScroll
      >
        <div className={styles.container}>
          <h3 className={styles.title}>{title}</h3>
          <div
            className={currentView === MAP_VIEW ? styles.mapContainer : styles.mapContainerHidden}
            ref={(ref) => (this.mapContainerElement = ref)}
          />
          {hasFullAddress && (
            <div
              className={
                currentView === STREET_VIEW
                  ? styles.streetViewContainer
                  : styles.streetViewContainerHidden
              }
              ref={(ref) => (this.streetViewContainerElement = ref)}
            />
          )}
          {hasFullAddress && (
            <MapControls
              lat={lat}
              lng={lng}
              className={styles.controlsWrapper}
              trackGetDirectionsClick={trackGetDirectionsClick}
            />
          )}
        </div>
      </RCUIOverlay>
    );
  }
}

MapOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  currentView: PropTypes.oneOf([MAP_VIEW, STREET_VIEW]).isRequired,
  map: MAP_PROP_TYPES,
  address: ADDRESS_PROP_TYPES,
  trackMapViewClick: PropTypes.func.isRequired,
  trackStreetViewClick: PropTypes.func.isRequired,
  trackGetDirectionsClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isOpen: state.detail.mapOverlayOpen,
  currentView: state.detail.mapOverlayView,
});

const mapActionsToProps = {
  close: closeMapOverlay,
};

export default connect(mapStateToProps, mapActionsToProps)(MapOverlay);
