// TODO: Refactor to use *_PRECISION constants
export const STATE = 'state';
export const SUBURB = 'suburb';
export const STREET = 'street';

export const ADDRESS_PRECISION = 'ADDRESS';
export const STREET_PRECISION = 'STREET';
export const SUBURB_PRECISION = 'SUBURB';
export const STATE_PRECISION = 'STATE';
export const POSTAL_CODE_PRECISION = 'POSTAL_CODE';
export const REGION_PRECISION = 'REGION';
