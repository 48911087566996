import { OPEN_TOOL_TIP, CLOSE_TOOL_TIP } from '../actions/constants';

const initialState = {
  isOpen: false,
  content: null,
  targetElement: null,
};

export default function tooltipReducer(state = initialState, action) {
  const { content, targetElement, options } = action;
  switch (action.type) {
    case OPEN_TOOL_TIP:
      return {
        ...state,
        isOpen: true,
        content,
        targetElement,
        options,
      };
    case CLOSE_TOOL_TIP:
      return {
        ...state,
        isOpen: false,
        content: null,
        targetElement: null,
      };

    default:
      return state;
  }
}
