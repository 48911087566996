import {
  TOGGLE_CONTENT_MODAL,
  SET_UI_ELEMENT_DIMENSIONS,
  SHOW_PROGRESS_LOADER,
  HIDE_PROGRESS_LOADER,
  SET_HOVERED_LISTING,
} from '../actions/constants';

export const initialState = {
  visibleModal: null,
  elementDimensions: {},
  isLoading: false,
  hoveredListingId: undefined,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CONTENT_MODAL:
      return {
        ...state,
        visibleModal: action.modalElement || null,
      };

    case SET_UI_ELEMENT_DIMENSIONS:
      return {
        ...state,
        elementDimensions: {
          ...state.elementDimensions,
          [action.id]: {
            width: action.width,
            height: action.height,
          },
        },
      };

    case SHOW_PROGRESS_LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case HIDE_PROGRESS_LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case SET_HOVERED_LISTING:
      return {
        ...state,
        hoveredListingId: action.hoveredListingId,
      };

    default:
      return state;
  }
}
