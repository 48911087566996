import PropTypes from 'prop-types';

export const DATE = 'date';
export const RELEVANCY = 'featured';
export const PRICE = 'price';
export const TENANCY = 'tenancy.numberOfTenants';
export const NET_INCOME = 'tenancy.netIncome';
export const CALCULATED_YIELD = 'calculatedYield';

export type SortOptionsType = {
  name: string;
  direction: string;
  displayText: string;
  longDisplayText?: string;
  value: string;
  bff?: string;
  omniturePage: string;
  omnitureClick: string;
};

export const RELEVANCY_SORT: SortOptionsType = {
  name: RELEVANCY,
  direction: 'asc',
  displayText: 'Featured',
  value: `${RELEVANCY}-asc`,
  omniturePage: 'featured',
  omnitureClick: 'featured',
};

export const DATE_DESC_SORT: SortOptionsType = {
  name: DATE,
  direction: 'desc',
  displayText: 'Date (Newest - Oldest)',
  value: `${DATE}-desc`,
  bff: 'listing-date-newest-first',
  omniturePage: 'date (newest - oldest)',
  omnitureClick: 'date:desc',
};

export const DATE_ASC_SORT: SortOptionsType = {
  name: DATE,
  direction: 'asc',
  displayText: 'Date (Oldest - Newest)',
  value: `${DATE}-asc`,
  bff: 'listing-date-oldest-first',
  omniturePage: 'date (oldest - newest)',
  omnitureClick: 'date:asc',
};

export const PRICE_DESC_SORT: SortOptionsType = {
  name: PRICE,
  direction: 'desc',
  displayText: 'High - Low',
  longDisplayText: 'Price (High - Low)',
  value: `${PRICE}-desc`,
  bff: 'price-descending',
  omniturePage: 'price (high - low)',
  omnitureClick: 'price:desc',
};

export const PRICE_ASC_SORT: SortOptionsType = {
  name: PRICE,
  direction: 'asc',
  displayText: 'Low - High',
  longDisplayText: 'Price (Low - High)',
  value: `${PRICE}-asc`,
  bff: 'price-ascending',
  omniturePage: 'price (low - high)',
  omnitureClick: 'price:asc',
};

export const TENANCY_DESC_SORT: SortOptionsType = {
  name: TENANCY,
  direction: 'desc',
  displayText: 'Tenants (High - Low)',
  value: `${TENANCY}-desc`,
  omniturePage: 'tenants (high - low)',
  omnitureClick: 'tenants:desc',
};

export const TENANCY_ASC_SORT: SortOptionsType = {
  name: TENANCY,
  direction: 'asc',
  displayText: 'Tenants (Low - High)',
  value: `${TENANCY}-asc`,
  omniturePage: 'tenants (low - high)',
  omnitureClick: 'tenants:asc',
};

export const NET_INCOME_DESC_SORT: SortOptionsType = {
  name: NET_INCOME,
  direction: 'desc',
  displayText: 'High - Low',
  longDisplayText: 'Income (High - Low)',
  value: `${NET_INCOME}-desc`,
  omniturePage: 'income (high - low)',
  omnitureClick: 'income:desc',
};

export const NET_INCOME_ASC_SORT: SortOptionsType = {
  name: NET_INCOME,
  direction: 'asc',
  displayText: 'Low - High',
  longDisplayText: 'Income (Low - High)',
  value: `${NET_INCOME}-asc`,
  omniturePage: 'income (low - high)',
  omnitureClick: 'income:asc',
};

export const CALCULATED_YIELD_DESC_SORT: SortOptionsType = {
  name: CALCULATED_YIELD,
  direction: 'desc',
  displayText: 'High - Low',
  longDisplayText: 'Yield (High - Low)',
  value: `${CALCULATED_YIELD}-desc`,
  omniturePage: 'yield (high - low)',
  omnitureClick: 'yield:desc',
};

export const CALCULATED_YIELD_ASC_SORT: SortOptionsType = {
  name: CALCULATED_YIELD,
  direction: 'asc',
  displayText: 'Low - High',
  longDisplayText: 'Yield (Low - High)',
  value: `${CALCULATED_YIELD}-asc`,
  omniturePage: 'yield (low - high)',
  omnitureClick: 'yield:asc',
};

export const SORT_OPTIONS = [
  RELEVANCY_SORT,
  DATE_DESC_SORT,
  DATE_ASC_SORT,
  PRICE_DESC_SORT,
  PRICE_ASC_SORT,
  TENANCY_DESC_SORT,
  TENANCY_ASC_SORT,
  NET_INCOME_DESC_SORT,
  NET_INCOME_ASC_SORT,
  CALCULATED_YIELD_DESC_SORT,
  CALCULATED_YIELD_ASC_SORT,
];

const getSortOptionsByName = (name: string): SortOptionsType[] | undefined => {
  if (!name) return undefined;
  return SORT_OPTIONS.filter((option: SortOptionsType) => option.name === name);
};

export const getSortOptionByValue = (value?: string): SortOptionsType | undefined => {
  if (!value) return undefined;
  return SORT_OPTIONS.find((option: SortOptionsType) => option.value === value);
};

export const filterSortOptions = (sortOptions: string[]) =>
  sortOptions.map((optionName) => getSortOptionsByName(optionName)).flat();

export const sortOptionPropTypes = {
  name: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
