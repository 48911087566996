import {
  trackView as trackViewByArgonaut,
  trackEvent,
  clearView,
  init,
} from '@rea-argonaut/analytics';

const trackView = (viewDataGetter) => {
  clearView();
  trackViewByArgonaut(viewDataGetter);
};

export default {
  trackView,
  trackEvent,
  init,
};
