import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

class Schema extends Component {
  static serverSideRender(renderToString, script) {
    renderToString(
      <Schema script={script} />,
    );

    const head = Helmet.rewind();

    return head.script.toString();
  }

  render() {
    const { script } = this.props;
    return (
      <Helmet script={script} />
    );
  }
}

Schema.propTypes = {
  script: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      innerHTML: PropTypes.string.isRequired,
    }),
  ),
};

export default Schema;
