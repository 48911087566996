import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Icon.scss';
import * as paths from './paths/index';

const Icon = ({ name = 'warning', fill = 'currentColor', size = '1em', width, height, className, ...props }) => {
  const iconPaths = paths[name] || paths.warning;

  if (!iconPaths) {
    process.exit();
  }

  return (
    <svg
      {...props}
      className={classnames([styles.svg, className])}
      width={width || size}
      height={height || size}
      fill={fill}
      data-id={`icon-${name}`}
      viewBox="0 0 40 40"
    >
      {iconPaths.map((path, i) => <path key={i} d={path} />)}
    </svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  fill: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
