import cloneDeep from 'lodash/cloneDeep';

import {
  SET_SAVED_LISTINGS,
  SAVE_LISTING,
  SAVE_LISTING_SUCCESS,
  DELETE_LISTING,
  DELETE_LISTING_TO_SAVE,
  SHOW_SAVE_PROPERTY_OVERLAY,
  HIDE_SAVE_PROPERTY_OVERLAY,
  SHOW_PROPERTY_ALERT_OVERLAY,
  HIDE_PROPERTY_ALERT_OVERLAY,
  SET_LOGIN_STATE,
  NOT_LOGIN_SAVED_SEARCH,
  CREATE_EMAIL_ALERT,
  RESET_LOGIN_STATE,
} from '../actions/constants';

export default function myRCA(state = {}, action) {
  switch (action.type) {
    case SET_SAVED_LISTINGS:
      return {
        ...state,
        savedListings: action.savedListings.map((s) => ({
          id: s.id,
          'listing-id': s['listing-id'],
        })),
      };

    case SAVE_LISTING:
      return {
        ...state,
        savedListings: [
          ...state.savedListings,
          {
            'listing-id': action.id,
            id: null,
          },
        ],
      };

    case SAVE_LISTING_SUCCESS: {
      const savedListings = cloneDeep(state.savedListings); // Deep copy state.savedListings
      const savedListingIndex = savedListings.map((s) => s['listing-id']).indexOf(action.listingId);
      savedListings[savedListingIndex].id = action.bookmarkId; // Mutate bookmarkId

      return {
        ...state,
        savedListings,
      };
    }

    case DELETE_LISTING: {
      const savedListingIndex = state.savedListings.map((s) => s['listing-id']).indexOf(action.id);

      return {
        ...state,
        savedListings: [
          ...state.savedListings.slice(0, savedListingIndex),
          ...state.savedListings.slice(savedListingIndex + 1),
        ],
      };
    }

    case DELETE_LISTING_TO_SAVE: {
      return {
        ...state,
        listingIdToSave: undefined,
      };
    }

    case NOT_LOGIN_SAVED_SEARCH: {
      return {
        ...state,
        alertSet: false,
      };
    }

    case CREATE_EMAIL_ALERT: {
      return {
        ...state,
        alertSet: true,
      };
    }

    case SHOW_SAVE_PROPERTY_OVERLAY:
      return {
        ...state,
        showSavePropertyOverlay: true,
      };

    case HIDE_SAVE_PROPERTY_OVERLAY:
      return {
        ...state,
        showSavePropertyOverlay: false,
      };

    case SHOW_PROPERTY_ALERT_OVERLAY:
      return {
        ...state,
        showPropertyAlertOverlay: true,
      };

    case HIDE_PROPERTY_ALERT_OVERLAY:
      return {
        ...state,
        showPropertyAlertOverlay: false,
      };

    case SET_LOGIN_STATE:
      return {
        ...state,
        isSignedIn: action.isSignedIn,
      };

    case RESET_LOGIN_STATE:
      return {
        ...state,
        isSignedIn: false,
        token: null,
        savedListings: [],
        alertSet: false,
      };

    default:
      return state;
  }
}
