import { getPropertyTypeFromUrl } from '../../../shared/model/constants/propertyTypes';

export default (searchCondition = '') => {
  const searchConditionParts = searchCondition.split('/').filter(Boolean);
  const firstSearchCondition = searchConditionParts[0];

  if (searchConditionParts.length > 1) {
    const [locationsUrl, propertyTypeUrl] = searchConditionParts;

    return { locationsUrl, propertyTypeObject: getPropertyTypeFromUrl(propertyTypeUrl) };
  }

  if (getPropertyTypeFromUrl(firstSearchCondition)) {
    return { propertyTypeObject: getPropertyTypeFromUrl(firstSearchCondition) };
  }

  return { locationsUrl: firstSearchCondition };
};
