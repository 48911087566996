import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

let store;

export const generateStore = (initialState = {}, isSignedIn = false, token, listingIdToSave) => {
  const IS_CLIENT_SIDE = typeof window !== 'undefined';
  const HAS_CHROME_DEV_TOOLS = IS_CLIENT_SIDE && window.__REDUX_DEVTOOLS_EXTENSION__; // eslint-disable-line no-underscore-dangle
  const devTools =
    IS_CLIENT_SIDE && HAS_CHROME_DEV_TOOLS
      ? window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line no-underscore-dangle
      : (f) => f;

  if (store && IS_CLIENT_SIDE) return store;

  store = createStore(
    reducers,
    {
      myRCA: {
        isSignedIn,
        token,
        savedListings: [],
        listingIdToSave,
      },
      ...initialState,
    },
    compose(applyMiddleware(thunk), devTools),
  );

  return store;
};

export const getStore = () => store || generateStore({});

export default generateStore;
