import { combineReducers } from 'redux';
import myRCA from './myRCA';
import invest from './invest';
import ui from './ui';
import tooltip from './tooltip';
import enquiry from './enquiry';
import detail from '../../detail/reducer';

const reducers = combineReducers({
  myRCA,
  ui,
  tooltip,
  enquiry,
  invest,
  detail,
});

export default reducers;
