import React from 'react';
import UniversalRouter from 'universal-router';
import history from '../client/service/history';
import fetchDataWithTimeout from './fetchDataWithTimeout';
import { prepareFeatureFlags } from '../shared/experiments/phasedRelease/config';

export class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.status = 404;
  }
}

const renderComponentWithData = (Component, context, data) => {
  const {
    hostname,
    userAgent,
    location,
    params,
    cookies: { isSignedIn, token, recentSearches, listingIdToSave },
    releases,
    experimentsManager,
  } = context;

  const componentParams = {
    ...params,
    data,
    userAgent,
    hostname,
    isSignedIn,
    token,
    releases,
    recentSearches,
    listingIdToSave,
    experimentsManager,
  };

  return <Component params={componentParams} location={location} history={history} />;
};

const fetchDataAndRender = (Component, context) => {
  const {
    location: { pathname, search },
    params,
    releases,
  } = context;

  return fetchDataWithTimeout(
    Component.fetchData(context),
    {
      url: `${pathname}${search}`,
      params,
      featureFlags: prepareFeatureFlags(releases),
    },
    20000,
  ).then((data) => {
    if (data.error) {
      return Promise.reject(data.error);
    }
    return renderComponentWithData(Component, context, data);
  });
};

const routes = [
  {
    path: '/:channel(for-sale|for-lease|sold|leased)?/(property-?.*-):listingId(\\d+)',
    action: (context) =>
      import(/* webpackChunkName: "detail" */ './app/DetailApp').then((Component) =>
        fetchDataAndRender(Component.default, context),
      ),
  },
  {
    path: '/:channel(for-sale|for-lease|sold|leased)(/.*)',
    action: (context) =>
      import(/* webpackChunkName: "search-result" */ './app/SearchResultApp').then((Component) =>
        fetchDataAndRender(Component.default, context),
      ),
  },
  {
    path: '/:channel(invest)(/.*)',
    action: (context) =>
      import(/* webpackChunkName: "invest" */ './app/InvestorApp').then((Component) =>
        fetchDataAndRender(Component.default, context),
      ),
  },
  {
    path: '/:channel(for-sale|for-lease|sold|leased|invest)',
    action: (context) =>
      import(/* webpackChunkName: "homepage" */ './app/HomepageApp').then((Component) =>
        renderComponentWithData(Component.default, context),
      ),
  },
  {
    path: '/find-agent',
    action: (context) =>
      import(/* webpackChunkName: "customer-homepage" */ './app/CustomerHomepageApp').then(
        (Component) =>
          renderComponentWithData(Component.default, {
            ...context,
            params: context.location.query,
          }),
      ),
  },
  {
    path: '/',
    action: (context) =>
      import(/* webpackChunkName: "homepage" */ './app/HomepageApp').then((Component) =>
        renderComponentWithData(Component.default, {
          ...context,
          params: context.location.query,
        }),
      ),
  },
  // TODO: [RCOTA POC] Refactor or remove this route depending on how the PoC progresses,
  // this also needs to be tested
  {
    path: '/embed/latest-listings',
    action: (context) =>
      import(/* webpackChunkName: "latest-listings" */ './app/LatestListingsApp').then(
        (Component) => fetchDataAndRender(Component.default, context),
      ),
  },
  {
    path: '(.*)',
    action: ({ pathname }) => Promise.reject(new NotFoundError(`Path not found: ${pathname}`)),
  },
];

export default new UniversalRouter(routes);
