import PropTypes from 'prop-types';

export const PRICE_PROP_TYPES = PropTypes.shape({
  forSale: PropTypes.shape({
    display: PropTypes.string.isRequired,
    displayTax: PropTypes.string,
    isPriceHidden: PropTypes.bool,
    advertisingRange: PropTypes.string,
  }),
  forLease: PropTypes.shape({
    display: PropTypes.string,
    displayPsm: PropTypes.string,
    displayTax: PropTypes.string,
    isPriceHidden: PropTypes.bool,
    excludesOutgoings: PropTypes.bool,
  }),
  sold: PropTypes.shape({
    display: PropTypes.string.isRequired,
    isPriceHidden: PropTypes.bool,
    advertisingRange: PropTypes.string,
  }),
  leased: PropTypes.shape({
    display: PropTypes.string.isRequired,
    isPriceHidden: PropTypes.bool,
  }),
});

export const CHANNEL_AUTHORITY_TYPE_PROP_TYPES = PropTypes.oneOf([
  'auction',
  'tender',
  'eoi',
  'setSale',
  'saleByNegotiation',
  'offersToPurchase',
]);

export const CHANNEL_AUTHORITY_PROP_TYPES = PropTypes.shape({
  channelType: CHANNEL_AUTHORITY_TYPE_PROP_TYPES.isRequired,
  displayName: PropTypes.string.isRequired,
  eventTime: PropTypes.string,
});

export const ATTRIBUTES_PANEL_PROP_TYPES = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
);

export const MAP_PROP_TYPES = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  precision: PropTypes.string.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  thumbnail: PropTypes.string,
});

export const TOURS_PROP_TYPES = PropTypes.arrayOf(
  PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const WEBSITES_PROP_TYPES = PropTypes.arrayOf(
  PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const DOCUMENTS_PROP_TYPES = PropTypes.arrayOf(
  PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const VIDEO_PROP_TYPES = PropTypes.shape({
  url: PropTypes.string,
  youtubeId: PropTypes.string,
});

export const DISPLAY_ADDRESS_PROP_TYPES = PropTypes.shape({
  streetAddress: PropTypes.string.isRequired,
  suburbAddress: PropTypes.string.isRequired,
});

export const ADDRESS_PROP_TYPES = PropTypes.shape({
  streetAddress: PropTypes.string.isRequired,
  suburbAddress: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  postcode: PropTypes.string,
  suburb: PropTypes.string,
  marketingRegion: PropTypes.string,
  marketingSuburb: PropTypes.string,
});

export const AGENCY_ADDRESS_PROP_TYPES = DISPLAY_ADDRESS_PROP_TYPES;

export const PHONE_PROP_TYPES = PropTypes.shape({
  display: PropTypes.string.isRequired,
  dial: PropTypes.string.isRequired,
});

export const BRANDING_PROP_TYPES = PropTypes.shape({
  color: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
});

export const TENANCY_PROP_TYPE = PropTypes.shape({
  keyTenant: PropTypes.string,
  numberOfTenants: PropTypes.number,
  fullyOccupied: PropTypes.bool,
  netIncome: PropTypes.number,
});

export const NEARBY_PROPERTIES_CATEGORY_PROP_TYPES = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      distance: PropTypes.string.isRequired,
      geocode: PropTypes.shape({
        lon: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export const NEARBY_PROPERTIES_CATEGORIES_PROP_TYPES = PropTypes.arrayOf(
  PropTypes.shape(NEARBY_PROPERTIES_CATEGORY_PROP_TYPES),
).isRequired;

export const DESCRIPTION_METADATA_PROP_TYPES = PropTypes.shape({
  phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    dial: PropTypes.string.isRequired,
    startIndex: PropTypes.number.isRequired,
    endIndex: PropTypes.number.isRequired,
  })),
});
