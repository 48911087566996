import React, { createContext } from 'react';
import activeExperiments from '../experiments';
import ExperimentsRegistry from './session/registry';

const ExperimentsContext = createContext(
  new ExperimentsRegistry(activeExperiments),
);

export const withExperimentVariant = ({ propName, experimentName, variantName }) => WrappedComponent => (props) => {
  if (!propName || !experimentName || !variantName) {
    // eslint-disable-next-line max-len
    throw new TypeError('hasExperimentVariant requires propName, experimentName and variantName arguments are provided');
  }

  return (
    <ExperimentsContext.Consumer>
      {
        experiments => <WrappedComponent
          {...props}
          {...{ [propName]: !!experiments.isActiveVariant(experimentName, variantName) }}
        />
      }
    </ExperimentsContext.Consumer>
  );
};

export default ExperimentsContext;
