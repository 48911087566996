import * as actionTypes from './actionTypes';
import { channelObjects } from '../../../shared/model/constants/channels';
import { MAP_VIEW } from '../MapOverlay';

export const initialState = {
  currentChannel: channelObjects.ForSale,
  callModalOpen: false,
  socialModalOpen: false,
  mediaViewerOpen: false,
  mediaViewerInitialItem: 0,
  mapOverlayOpen: false,
  mapOverlayView: MAP_VIEW,
};

export const prepareDetailStore = () => ({
  detail: initialState,
});

export default (state = initialState, action) => {
  const actions = {
    [actionTypes.OPEN_CALL_MODAL]: { callModalOpen: true },
    [actionTypes.CLOSE_CALL_MODAL]: { callModalOpen: false },
    [actionTypes.OPEN_SOCIAL_MODAL]: { socialModalOpen: true },
    [actionTypes.CLOSE_SOCIAL_MODAL]: { socialModalOpen: false },
    [actionTypes.SET_DETAIL_LISTING]: { listing: action.listing },
    [actionTypes.SET_CURRENT_CHANNEL]: { currentChannel: action.channelObject },
    [actionTypes.OPEN_MEDIA_VIEWER]: { mediaViewerOpen: true, mediaViewerInitialItem: action.initialItemIndex, mediaViewerInitialMediaType: action.mediaType },
    [actionTypes.CLOSE_MEDIA_VIEWER]: { mediaViewerOpen: false },
    [actionTypes.OPEN_MAP_OVERLAY]: { mapOverlayOpen: true, mapOverlayView: action.view || MAP_VIEW },
    [actionTypes.CLOSE_MAP_OVERLAY]: { mapOverlayOpen: false },
  };

  return {
    ...state,
    ...actions[action.type],
  };
};
