import PropTypes from 'prop-types';
import { CHANNEL_PROP_TYPES, PROPERTY_TYPE_OBJECT_PROP_TYPES } from '../../../shared/model/types';
import { MAP_VIEW_PROP_TYPES } from './query/mapViewOptions';
import { sortOptionPropTypes } from './query/sortOptions';

export const BUILDING_BRANDING_PROP_TYPES = PropTypes.shape({
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
});

export const BUILDING_LOGO_PROP_TYPES = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const ADDRESS_PROP_TYPES = PropTypes.shape({
  streetAddress: PropTypes.string.isRequired,
  suburbAddress: PropTypes.string.isRequired,
});

const BOUNDING_BOX_PROP_TYPES = {
  northEastLatitude: PropTypes.number.isRequired,
  northEastLongitude: PropTypes.number.isRequired,
  southWestLatitude: PropTypes.number.isRequired,
  southWestLongitude: PropTypes.number.isRequired,
};

export const REFINEMENT_PROP_TYPES = PropTypes.shape({
  channel: PropTypes.string,
  channelObject: CHANNEL_PROP_TYPES,
  energyEfficiency: PropTypes.number,
  numParkingSpaces: PropTypes.number,
  sortObject: PropTypes.shape(sortOptionPropTypes),
  searchWithin: PropTypes.string,
  tenureType: PropTypes.string,
  keywords: PropTypes.string,
  propertyTypes: PropTypes.arrayOf(PROPERTY_TYPE_OBJECT_PROP_TYPES),
  onSubmit: PropTypes.func,
  minPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minFloorArea: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxFloorArea: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minSiteArea: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxSiteArea: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locations: PropTypes.arrayOf(PropTypes.string),
  mapView: MAP_VIEW_PROP_TYPES,
  boundingBox: PropTypes.shape(BOUNDING_BOX_PROP_TYPES),
  pageSize: PropTypes.number,
});

export const SRP_PAGE_INFO_PROP_TYPES = PropTypes.shape({
  activePage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
});
