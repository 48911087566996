import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import isUndefined from 'lodash/isUndefined';

export const formatQueryStringParameter = (uri: string, key: string, value: string) => {
  const existingQueryParam = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = includes(uri, '?') ? '&' : '?';
  if (uri.match(existingQueryParam)) {
    return uri.replace(existingQueryParam, `$1${key}=${value}$2`);
  }
  return `${uri}${separator}${key}=${value}`;
};

export const formatPathFromQuery = (
  queries: Record<string, string>,
  href: string,
  origin: string,
) => {
  const newUrl = reduce(
    queries,
    (path, value, key) => {
      const hasValue = !isUndefined(value) || value !== '';
      return hasValue ? formatQueryStringParameter(path, key, value) : path;
    },
    href,
  );

  return newUrl.replace(origin, '');
};

export const replaceQueryString = (
  queries: Record<string, string>,
  href = window.location.href,
  origin = window.location.origin,
  title = document.title,
) => {
  if (window.history.replaceState) {
    // Replace current history entry
    window.history.replaceState(null, title, formatPathFromQuery(queries, href, origin));
  }
};

export default replaceQueryString;
