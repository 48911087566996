import React from 'react';
import PropTypes from 'prop-types';
import AppContainer from './AppContainer';
import { RELEASE_PROP_TYPES } from '../../shared/experiments/phasedRelease/config';
import ErrorHandler, { ERROR_PROP_TYPES } from '../error/ErrorContainer';
import { USER_AGENT_PROP_TYPES } from '../../shared/model/types';

const ErrorApp = ({ params }) => {
  const { isSignedIn, error, userAgent, releases } = params;
  const { status, message, siteSection } = error;

  return (
    <AppContainer
      error={error}
      isSignedIn={isSignedIn}
      userAgent={userAgent}
      releases={releases}
    >
      <ErrorHandler status={status} message={message} siteSection={siteSection} />
    </AppContainer>
  );
};

ErrorApp.propTypes = {
  params: PropTypes.shape({
    channel: PropTypes.string,
    // TODO: refactor error
    error: PropTypes.shape(ERROR_PROP_TYPES),
    isSignedIn: PropTypes.bool,
    releases: RELEASE_PROP_TYPES.isRequired,
    userAgent: USER_AGENT_PROP_TYPES,
  }).isRequired,
};

export default ErrorApp;
