import PropTypes from 'prop-types';

export const ELITE_PLUS = 'eliteplus';
export const ELITE = 'elite';
export const ENHANCED = 'enhanced';
export const BASIC = 'basic';
export const BUILDING_PROFILE = 'buildingprofile';

export const PRODUCT_TYPES = PropTypes.oneOf([
  ELITE_PLUS,
  ELITE,
  ENHANCED,
  BASIC,
  BUILDING_PROFILE,
]);
