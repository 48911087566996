/* eslint-disable import/no-import-module-exports */
import * as errorReporter from './js/client/tracking/errorReporter';
import resolveRouteFromLocation from './js/client/client';
import { disableScrollRestoration } from './js/client/service/history';

if (module.hot) {
  module.hot.accept();
}

errorReporter.initialise();
resolveRouteFromLocation();
disableScrollRestoration();
