import PropTypes from 'prop-types';
import get from 'lodash/get';
import { parseConfig, DISABLE_QUALTRICS, SHOW_SOLD_DISCLAIMER } from '../../config';

const ONE_MONTH = 60 * 60 * 24 * 30 * 1000;

export const PHASED_RELEASE_UPPER_LIMIT = 10000;

export const RELEASE_PROP_TYPE = PropTypes.bool;

export const RELEASE_PROP_TYPES = PropTypes.shape({});

export const FEATURE_FLAGS_PROP_TYPES = PropTypes.arrayOf(PropTypes.string).isRequired;

// eslint-disable-next-line max-len
export const prepareFeatureFlags = (availableReleases) =>
  Object.keys(availableReleases).filter((key) => availableReleases[key]);

// Names must be in camel case
const releasesConfig = [
  {
    name: DISABLE_QUALTRICS,
    duration: ONE_MONTH,
  },
  {
    name: SHOW_SOLD_DISCLAIMER,
    duration: ONE_MONTH,
  },
];

const prepareRelease = (release) => {
  const uppercaseName = release.name.toUpperCase();
  const configName = `RELEASE_${uppercaseName}_PERCENTAGE`;
  const environmentConfig = parseConfig();

  return {
    ...release,
    percentage: environmentConfig[configName] || 0,
  };
};

export const isRelease = (release) => !!get(parseConfig(), `RELEASES[${release}]`);

export default releasesConfig.map(prepareRelease);
