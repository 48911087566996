import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import RCUIButton from '../../shared/components/RCUIButton';
import Icon from '../../shared/components/Icon/index';
import styles from './MapControls.scss';
import buttonStyles from '../../shared/components/RCUIButton.scss';
import { openMapOverlay } from './reducer/creators';
import { STREET_VIEW, MAP_VIEW } from './MapOverlay';

export const MapControls = ({
  className = styles.buttonGroup,
  expandClassName = styles.button,
  streetViewClassName = styles.button,
  directionsClassName = styles.directionsButton,
  expandOption,
  lat,
  lng,
  trackGetDirectionsClick,
  openMap,
}) => {
  const directionsLink = `https://www.google.com.au/maps?saddr=My+Location&daddr=${lat},${lng}`;

  return (
    <div className={className}>
      <RCUIButton
        classNames={streetViewClassName}
        onClickHandler={() => openMap(STREET_VIEW)}
      >
        <span className={buttonStyles.buttonContent}>
          <Icon name="streetView" className={styles.buttonIcon} />
          Streetview
        </span>
      </RCUIButton>
      <a
        className={directionsClassName}
        href={directionsLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackGetDirectionsClick}
      >
        <Icon name="directions" className={styles.buttonIcon} />
        Directions
      </a>
      <RCUIButton
        classNames={expandClassName}
        onClickHandler={() => openMap(MAP_VIEW)}
      >
        <span className={buttonStyles.buttonContent}>
          <Icon name={expandOption ? 'expand' : 'mapPin'} className={styles.buttonIcon} />
          { expandOption ? 'Expand' : 'Map' }
        </span>
      </RCUIButton>
    </div>
  );
};

MapControls.propTypes = {
  className: PropTypes.string,
  expandClassName: PropTypes.string,
  streetViewClassName: PropTypes.string,
  directionsClassName: PropTypes.string,
  expandOption: PropTypes.bool,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  trackGetDirectionsClick: PropTypes.func.isRequired,
  openMap: PropTypes.func.isRequired,
};

export default connect(null, { openMap: openMapOverlay })(MapControls);
