import { Loader } from '@googlemaps/js-api-loader';
import { parseConfig } from './config';

type Config = {
  GOOGLE_MAPS_CLIENT_ID: string;
  GOOGLE_MAPS_CHANNEL: string;
};

export const googleMapLoader = () => {
  const config = parseConfig() as Config;
  const { GOOGLE_MAPS_CLIENT_ID, GOOGLE_MAPS_CHANNEL } = config;

  // eslint-disable-next-line no-new
  return new Loader({
    apiKey: '',
    client: GOOGLE_MAPS_CLIENT_ID,
    channel: GOOGLE_MAPS_CHANNEL,
  });
};
