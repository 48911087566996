import Cookie from 'js-cookie';

export function getCookie(name) {
  return Cookie.get(name);
}

export function removeCookie(name, options = {}) {
  return Cookie.remove(name, options);
}

export function setCookie(name, value, options) {
  return Cookie.set(name, value, options);
}
